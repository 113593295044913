import React, { useState, useEffect, useRef } from "react";
import {
  assistantSettingURl,
  cancelWithoutSaving,
  externalAppSettings,
} from "../../utils/Constants";
import { IAssistantDetails } from "../../components/createProject/models/project";
import "./assistantConfiguration.scss";
import t from "../../localization/en/translation.json";
import BreadcrumbComponent from "../../components/breadcrumb/Breadcrumb";
import { APP_STATE } from "../../store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { getProjectDetails } from "../../store/actions/project-actions";
import { useHistory, useParams } from "react-router";

interface IAssistantConfigurationProps extends IAssistantDetails {
  isProjectNonActive: boolean;
}

export const AssistantConfiguration = (
  assistantDetails: IAssistantConfigurationProps
) => {
  const history = useHistory();
  const assistantConfigRef = useRef<HTMLIFrameElement>(null);
  const record = useSelector((state: APP_STATE) => state.projects.record);
  const [assistantConfigUrl, setAssistantConfigUrl] = useState("");

  const breadcrumbObject = [
    { label: t.breadcrumb_title, href: "/", active: true },
    {
      label: `${record?.name}`,
      href: `/project/${record?.id}`,
      active: true,
    },
    {
      label: t.manage_assistant,
      href: "",
      active: false,
      disabled: true,
    },
  ];

  const params: { id: any } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params) {
      dispatch(getProjectDetails({ id: params.id }));
    }
  }, [params, dispatch]);

  useEffect(() => {
    const assistantUrl = record?.copilotDetails?.siteURL || "";
    if (assistantUrl) {
      const assistantConfigUrl = assistantUrl + assistantSettingURl;
      setAssistantConfigUrl(assistantConfigUrl);
    }
  }, [record]);

  const handleIframeLoad = () => {
    const timerId = setTimeout(() => {
      const iframe = assistantConfigRef?.current;
      if (iframe) {
        const iframeWindow = iframe.contentWindow;
        if (iframeWindow) {
          iframeWindow.postMessage(
            externalAppSettings,
            record?.copilotDetails?.siteURL
          );
        }
      }
    }, 1000);
    return () => clearTimeout(timerId);
  };
  useEffect(() => {
    // Add an event listener to listen to messages from the iframe
    const handleIframeMessage = (event: MessageEvent) => {
      if (assistantConfigUrl) {
        const url = new URL(assistantConfigUrl);
        if (event.origin !== url.origin) return;
        if (event?.data === cancelWithoutSaving) {
          //GO TO PREV PAGE
          history.push(`/project/${params?.id}`);
        }
      }
    };
    window.addEventListener("message", handleIframeMessage);
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [assistantConfigUrl, history, params?.id]);

  return (
    <>
      {record && record.id && (
        <div className="manage-assistant-page">
          <div className="title-wrapper">
            <BreadcrumbComponent items={breadcrumbObject}></BreadcrumbComponent>
            <div>
              <h2>{t.manage_assistant}</h2>
            </div>
          </div>

          <div className="page-content">
            {assistantConfigUrl && (
              <iframe
                ref={assistantConfigRef}
                title="assistantConfig"
                id="assistantConfig"
                className="assistantConfig"
                src={assistantConfigUrl}
                style={{ border: "none" }}
                height="100%"
                width="100%"
                onLoad={handleIframeLoad}
              ></iframe>
            )}
          </div>
        </div>
      )}
    </>
  );
};
