import React, { useState } from 'react';
import {
  AppButton,
  AppIcon,
  AppModal,
  MotifAppTextArea,
} from '../../theme';
import { ClearButton, AsyncTypeahead } from 'react-bootstrap-typeahead';
import t from '../../localization/en/translation.json';
import { searchUsers } from '../../store/apis/projectApi';
import { getStringWithBold } from '../../utils/helper-utility';
import './projectDetails.scss';
import { search } from '../../theme/icons';

interface IProjectGovernanceModalProps {
  onClose: () => void;
  onConfirmCheckIn: (additionalInfo: string, engagementUser: string) => void;
}
const PAGE_SIZE = 10;
const APPROVER_SORT_COLUMN = 'displayName';

const ProjectGovernanceModal: React.FC<IProjectGovernanceModalProps> = ({
  onClose,
  onConfirmCheckIn,
}) => {
  const [engagementSearchUser, setEngagementSearchUser] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [engagementUser, setEngagementUser] = useState({} as any);
  const [additionalInfo, setAdditionalInfo] = useState('');

  const makeSearchUsersCall = async (
    query,
    skipToken = null,
    pageNumber = 1
  ) => {
    const payload = {
      pageSize: PAGE_SIZE + 1,
      pageNumber: pageNumber,
      sortColumn: APPROVER_SORT_COLUMN,
      skipToken: skipToken,
      filters: [
        {
          filterText: query,
        },
      ],
    };

    try {
      setSearchLoader(true);
      const action = {
        type: t.edit_project_search_placeholder,
        payload: payload,
      };
      const result = await searchUsers(action);
      if (result && result.data && result.data.records) {
        setEngagementSearchUser(
          result.data.records.map((option) => ({
            label: option.displayName,
            email: option.mail,
            value: option.id,
          }))
        );
      } else {
        setEngagementSearchUser([]);
      }
    } catch (error) {
    } finally {
      setSearchLoader(false);
    }
  };

  const handleUsersSelection = (selectedArray: any[]) => {
    if (!selectedArray.length) {
      setEngagementUser({});
    } else {
      setEngagementUser({
        name: selectedArray[0].label,
        email: selectedArray[0].email,
        id: selectedArray[0].value,
      });
    }
  };

  const renderMenuItem = (option, props) => {
    const stringData = option.label + ' ' + option.email;
    return (
      <div
        className="async-dropdown"
        dangerouslySetInnerHTML={{
          __html: getStringWithBold(stringData, props.text),
        }}
      ></div>
    );
  };

  return (
    <AppModal
      newDesign={true}
      size="sm"
      showModal={true}
      title={t.project_gov_modal_title}
      modalClass="project-governance-modal"
      onModalClose={onClose}
      hideFooterCancelBtn={true}
      additionalBtns={
        <React.Fragment>
          <AppButton type="button" color="secondary" onClick={() => onClose()}>
            {t.cancel}
          </AppButton>
          <AppButton disabled={engagementUser && !Object.keys(engagementUser)?.length} type="button" onClick={()=> onConfirmCheckIn(additionalInfo, engagementUser)}>
            {t.confirm}
          </AppButton>
        </React.Fragment>
      }
    >
      <div className="content">
        <p>{t.project_gov_modal_description}</p>
        <div className={'question'}>
          <span className="input-label">{t.ppedd_approver}</span>
          <div className={'search-wrapper'}>
            <div className="project-form-field">
              <AppIcon icon={search} />
              <AsyncTypeahead
                id="async-search"
                isLoading={searchLoader}
                filterBy={() => true}
                labelKey={(option) => `${option.label}`}
                minLength={3}
                onSearch={(value) => makeSearchUsersCall(value)}
                options={engagementSearchUser}
                onChange={(selected) => handleUsersSelection(selected)}
                emptyLabel={t.no_matches_found}
                renderMenuItemChildren={(option, props) =>
                  renderMenuItem(option, props)
                }
                inputProps={{ tabIndex: -1, placeholder: '' }}
              >
                {({ onClear, selected }) => (
                  <>
                    <div className="rbt-aux">
                      {selected.length > 0 && <ClearButton onClick={()=> {
                        setEngagementUser({});
                        onClear()
                      }
                      } />}
                    </div>
                  </>
                )}
              </AsyncTypeahead>
            </div>
          </div>
        </div>
        <div className={'question'}>
          <span className="input-label">{t.additional_info}</span>
          <MotifAppTextArea
            maxLength={1000}
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            placeholder={t.enterDescription}
          />
        </div>
      </div>
    </AppModal>
  );
};

export default ProjectGovernanceModal;
