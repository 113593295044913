import * as type from "../action-types";

type load = { id: string }

export const getProjectTypesLookUpAction = (payload?: load) => ({
    type: type.GET_PROJECT_TYPES,
    payload
});

export const getProjectTypesAction = () => ({
    type: type.GET_PROJECT_TYPES
});

export const updateProjectTypeAction = (payload?: load) => ({
    type: type.UPDATE_PROJECT_TYPE_REQUEST,
    payload
});

export const addProjectTypeAction = (payload?: load) => ({
    type: type.ADD_PROJECT_TYPE_REQUEST,
    payload
});

export const clearProjectTypeDataAction = () => ({
    type: type.FLUSH_PROJECT_TYPE_DATA
});

