import moment from "moment";
import { Project } from '../../components/createProject/models/project';

export const isExtendedProject = (projectDetails: Project) => {
  const isHandler = window.location.href.includes('handler');
  let isHandlerExpiration = false;
  if (isHandler) {
    const baseCode = window.location.href.split('handler/')[1];
    const baseParams = atob(baseCode);
    if(baseParams.indexOf('inactivityDays') !== -1){
        if(projectDetails.extendedOn === null) return false;

        const paramArray = baseParams.split('&');
        paramArray.forEach((param) => {
        const [key, value] = param.split('=');
            if (key === 'inactivityDays') {
                const extendedDate = moment(projectDetails.extendedOn);
                const today = moment();
                const noOfDays = today.diff(extendedDate, "days");
                isHandlerExpiration = !(noOfDays > parseInt(value));
            }
        });
        return isHandlerExpiration;
    }
  } else {
    return projectDetails.expirationDate &&
      (projectDetails.expirationDate as number) >=
        Date.parse(new Date().toUTCString());
  }
};

export const isProjectExpire = () => {
  const isHandler = window.location.href.includes("handler");
  let isHandlerExpiration = false;
  if(isHandler) {
    const baseCode = window.location.href.split("handler/")[1];
    const baseParams = atob(baseCode);
    const paramArray = baseParams.split("&");
    paramArray.forEach((param) => {
      const [key, value] = param.split("=");
      if(key === "extensionType" && value === "expiration") {
        isHandlerExpiration = true;
      }
    });
  }
  return isHandlerExpiration;
};
