import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import {
  getUsersList,
  searchAdminUserGrid,
  updateUserGrid,
  validateAdminUserRequest,
  validateAdminUserSuccess,
} from "../../store/actions/admin-users-actions";
import {
  getAdminProjectsByUserRequest,
  flushAdminProjectByUserAction,
} from "../../store/actions/admin-projects-actions";
import { updatefilterState } from "../../store/actions/list-actions";
import { getColumnConfig } from "./columnConfig";
import {
  DEFAULT_SORT,
  USER_GRID_FIELDS,
  VALIDATION_RESPONSE_TYPE,
} from "./adminUsersConstants";
import t from "../../localization/en/translation.json";
import {
  getPaginationModel,
  getDefaultPaginationModel,
} from "../../models/IPagination";
import { ClearButton, AsyncTypeahead } from "react-bootstrap-typeahead";
import AppNotification from "../../components/appNotification/appNotification";
import AdminProjectsAppsModal from "../../components/adminProjectsAppsModal/adminProjectsAppsModal";
import {
  ProjectModel,
  EProjectStatuses,
} from "../../containers/adminProjects/adminProjectsModel";
import List from "../../components/editableGrid/list/list";
import { getAdminConsoleAccess, getStringWithBold } from "../../utils/helper-utility";
import { EXTERNAL_USER } from "../manageUsers/listUsers/listUserConstants";
import { AppButton, AppModal, AppIcon, AppTooltip } from "../../theme";
import { info, search, warning } from "../../theme/icons";
import "./adminUsers.scss";
import { CrashBoundary } from "../../components/CrashBoundary";
import { UserTypes } from "../../utils/Constants";
import { isPortalUser } from "../../utils/helper-utility";
import { validateAdminUser } from "../../store/apis/projectApi";
import {
  UsersRowDataModel,
  warningDataModel,
  IState,
  AdmiUsersProps,
} from "./adminUsersModel";

type IProps = RouteComponentProps<any> & AdmiUsersProps;
class AdminUsers extends Component<IProps, IState> {
  acceptedTermText = "Yes";
  notAcceptedTermText = "No";
  handleRedirection: Function = () => null;
  notificationVariant = "";
  notificationMessage = "";
  gridApi;
  typeahead;
  constructor(props: IProps) {
    super(props);
    this.state = {
      showDeleteModal: false,
      showNotification: false,
      maxRecordsPerPage: 50,
      currentPage: 1,
      searchQuery: [],
      rowData: [],
      disabledResetAll: false,
      disabledResetAllEMG: false,
      updatedUserData: [],
      showGridUpdateCancelDialog: false,
      nextLocation: "",
      isAdminUsersGridUpdated: false,
      showProjectsModal: false,
      projectRowDataModel: [],
      userName: "",
      generateProjectsModal: false,
      columns: [],
      resetAllFilters: false,
      paginationPayload: getPaginationModel({
        ...getDefaultPaginationModel(),
        sortDirection: DEFAULT_SORT.sort,
        sortColumn: DEFAULT_SORT.colId,
        totalRecords: 0,
      }),
      warnings: [],
    };
  }
  componentDidMount() {
    const { history, sspRoles, serviceLines, subServiceLines } = this.props;

    this.refreshGrid();
    window.onbeforeunload = () => {
      if (this.state.isAdminUsersGridUpdated) {
        return false;
      }
    };

    this.handleRedirection = history.block((targetLocation) => {
      const { isAdminUsersGridUpdated, showGridUpdateCancelDialog } =
        this.state;
      if (isAdminUsersGridUpdated && showGridUpdateCancelDialog === false) {
        this.setState(
          {
            ...this.state,
            nextLocation: targetLocation.pathname,
          },
          () => {
            this.toggleCancelDialog(true);
          }
        );
        return false;
      }
    });
    const adminConsoleAccess = this.getUserAdminConsoleAccess();
    const applicableSspRoles = sspRoles.filter(
      (role) => role.userRoleGrain === "Portal"
    );
    const applicableUserTypes = Object.values(UserTypes);
    const columns = getColumnConfig(
      this.roleChanged,
      applicableSspRoles,
      this.userTypeChanged,
      applicableUserTypes,
      adminConsoleAccess,
      this.filterRoleOptionsBasedOnAccountType,
      serviceLines,
      this.serviceLinesChanged,
      subServiceLines,
      this.subServiceLinesChanged,
      this.filterSubServiceLineOptions,
      this.isExternalUser,
      this.generateClassNameOnRender,
      this.renderUserTypeWarningTemplate
    );
    this.setState({
      columns,
    });
    this.updateFilterState();
  }

  componentDidUpdate(prevProps, prevState) {
    const { userList, isAnyoneHasAcceptedTerms } = this.props;
    const {
      disabledResetAll,
      disabledResetAllEMG,
      paginationPayload,
      searchQuery,
    } = this.state;

    if (!prevState.disabledResetAll && disabledResetAll) {
      this.resetAllTermsToggle(true);
    }

    if (prevProps.isAnyoneHasAcceptedTerms !== isAnyoneHasAcceptedTerms) {
      this.resetAllTermsToggle(!isAnyoneHasAcceptedTerms);
    }

    this.refreshGridData(prevProps);

    if (!prevState.disabledResetAllEMG && disabledResetAllEMG) {
      this.resetAllEMGToggle(true);
    }

    if (prevState.paginationPayload !== paginationPayload) {
      this.refreshGrid();
      this.updateFilterState();
      this.setState({ resetAllFilters: false });
    }

    if (userList && userList !== prevProps.userList) {
      this.generateUsersRowData(
        (searchQuery.length && searchQuery) || userList
      );
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
  };

  refreshGridData = (prevProps) => {
    const { updateAdminUserGrid, userProjects } = this.props;
    const { rowData, generateProjectsModal } = this.state;

    if (rowData.length > 0) {
      if (
        updateAdminUserGrid !== prevProps.updateAdminUserGrid &&
        updateAdminUserGrid.success === true
      ) {
        this.refreshGrid();
        this.manageNotification();
      }
      if (userProjects && userProjects.length && generateProjectsModal) {
        this.generateProjectRowData();
      }
    }
  };

  resetAllTermsToggle = (disabledReset) => {
    const elementResetAllTerms = document.getElementById("reset-all-terms");
    if (elementResetAllTerms) {
      if (!disabledReset) {
        elementResetAllTerms.className = "link";
        elementResetAllTerms.onclick = this.resetAllTerms;
        elementResetAllTerms.style.pointerEvents = "auto";
      } else {
        elementResetAllTerms.className = "";
        elementResetAllTerms.onclick = null;
        elementResetAllTerms.style.pointerEvents = "none";
      }
    }
  };

  componentWillUnmount() {
    this.handleRedirection();
  }

  resetAllEMGToggle = (disableResetEMG) => {
    const resetAllEMGElm = document.getElementById("reset-all-EMG");

    if (resetAllEMGElm) {
      resetAllEMGElm.className = disableResetEMG ? "" : "link";
      resetAllEMGElm.onclick = disableResetEMG ? null : this.resetAllTerms;
      resetAllEMGElm.style.pointerEvents = disableResetEMG ? "none" : "auto";
    }
  };

  resetAllTerms = () => {
    this.gridApi.forEachNodeAfterFilterAndSort((rowNode) => {
      const rowsUpdated: any[] = [];
      const data = { ...rowNode.data };
      data.hasAcceptedTerms = this.notAcceptedTermText;
      data.action = "";
      rowsUpdated.push(data);
      if (rowsUpdated.length > 0) {
        this.gridApi.applyTransaction({
          update: rowsUpdated,
        });
      }
    });
    this.setState({
      ...this.state,
      disabledResetAll: true,
      isAdminUsersGridUpdated: true,
    });
  };

  resetAllEMG = () => {
    this.gridApi.forEachNodeAfterFilterAndSort((rowNode) => {
      const rowsUpdated: any[] = [];
      const data = { ...rowNode.data };

      data.actionEMG = "";
      rowsUpdated.push(data);
      if (rowsUpdated.length) {
        this.gridApi.applyTransaction({
          update: rowsUpdated,
        });
      }
    });
    this.setState({
      ...this.state,
      disabledResetAllEMG: true,
      isAdminUsersGridUpdated: true,
    });
  };

  resetRow = (userRow?) => {
    const { updatedUserData } = this.state;
    this.gridApi.forEachNodeAfterFilterAndSort((rowNode) => {
      const rowsUpdated: any[] = [];
      if (rowNode.id === userRow.id) {
        const data = { ...rowNode.data };
        data.hasAcceptedTerms = this.notAcceptedTermText;
        data.action = "";
        rowsUpdated.push(data);
        if (rowsUpdated.length > 0) {
          this.gridApi.applyTransaction({
            update: rowsUpdated,
          });
        }
      }
    });

    const user = _.cloneDeep(updatedUserData);
    const userExist = user?.find((item) => item.id === userRow.id);
    if (userExist) {
      user.map((data) => {
        if (data.id === userRow.id) {
          data.hasAcceptedTerms = false;
        }
        return data;
      });
    } else {
      const newData = {
        userPortalRoleId: userRow.userPortalRoleId,
        userPortalRoleName: userRow.userPortalRoleName,
        hasAcceptedTerms: false,
        id: userRow.id,
      };
      user.push(newData);
    }

    this.setState({
      ...this.state,
      updatedUserData: user,
      isAdminUsersGridUpdated: true,
    });
  };

  roleChanged = (selectedOption, userRow) => {
    const { value, label } = selectedOption;

    const sspRoles = this.props.sspRoles.filter(
      (role) => role.userRoleGrain === "Portal"
    );
    const sspRolesData = sspRoles.find((role) => role.id === value);
    if (sspRolesData?.id === userRow.user.userPortalRoleId) {
      this.setState({ ...this.state, updatedUserData: [] });
      return;
    }

    const { updatedUserData } = this.state;

    this.gridApi.forEachNodeAfterFilterAndSort((rowNode) => {
      const rowsUpdated: any[] = [];
      if (rowNode.data.id === userRow.id) {
        const data = { ...rowNode.data };
        data.roleId = value;
        data.userPortalRoleName = label;
        rowsUpdated.push(data);
      }
      if (rowsUpdated.length > 0) {
        this.gridApi.applyTransaction({
          update: rowsUpdated,
        });
      }
    });

    const user = _.cloneDeep(updatedUserData);
    const userExist = user?.find((item) => item.id === userRow.id);
    if (userExist) {
      user.map((data) => {
        if (data.id === userRow.id) {
          data.userPortalRoleId = value;
          data.userPortalRoleName = sspRolesData?.userRoleName;
        }
        return data;
      });
    } else {
      const newData = {
        userPortalRoleId: value,
        userPortalRoleName: sspRolesData?.userRoleName,
        hasAcceptedTerms: userRow.hasAcceptedTerms === this.acceptedTermText,
        id: userRow.id,
        userType: userRow?.userType,
      };
      user.push(newData);
    }

    this.setState({
      ...this.state,
      updatedUserData: user,
      isAdminUsersGridUpdated: true,
    });
  };

  userTypeChanged = async (selectedOption, userRow) => {
    const { value } = selectedOption;
    if (value === userRow.oldValue) return;
    const { updatedUserData } = this.state;
    const user = _.cloneDeep(updatedUserData);
    const validatedUser = await this.validateAdminUserType(value, userRow);
    let selectedServiceLine;
    if (validatedUser?.serviceLine)
      selectedServiceLine = this.props.serviceLines?.find(
        (line) => line.code === validatedUser.serviceLine?.code
      );
    const eyServiceLine =
      value.toUpperCase() === EXTERNAL_USER
        ? { code: null, desc: null }
        : validatedUser?.serviceLine
        ? validatedUser.serviceLine
        : userRow.user?.eyServiceLine;
    const eyServiceLineId =
      value.toUpperCase() === EXTERNAL_USER
        ? null
        : selectedServiceLine
        ? selectedServiceLine.id
        : userRow.serviceLineId;
    const eySubServiceLine =
      value.toUpperCase() === EXTERNAL_USER
        ? { code: null, desc: null }
        : validatedUser?.subServiceLine
        ? validatedUser.subServiceLine
        : userRow.user?.eySubServiceLine;

    this.gridApi.forEachNodeAfterFilterAndSort((rowNode) => {
      const rowsUpdated: any[] = [];
      if (rowNode.data.id === userRow.id) {
        const data = { ...rowNode.data };
        data.userType = value;
        data.serviceLineCode = eyServiceLine.code;
        data.serviceLineId = eyServiceLineId || null;
        data.subServiceLineCode = eySubServiceLine.code;
        rowsUpdated.push(data);
      }
      if (rowsUpdated.length > 0) {
        this.gridApi.applyTransaction({
          update: rowsUpdated,
        });
      }
    });

    const userExist = user?.find((item) => item.id === userRow.id);
    if (userExist) {
      user.map((data) => {
        if (data.id === userRow.id) {
          data.userType = value;
          data.eyServiceLine = eyServiceLine;
          data.eySubServiceLine = eySubServiceLine;
        }
        return data;
      });
    } else {
      const newData = {
        userType: value,
        userPortalRoleId: userRow.roleId,
        userPortalRoleName: userRow.userPortalRoleName,
        hasAcceptedTerms: userRow.hasAcceptedTerms === this.acceptedTermText,
        id: userRow.id,
        eyServiceLine: eyServiceLine,
        eySubServiceLine: eySubServiceLine,
      };
      const applicableRole = this.getApplicableUserRole(newData);
      user.push({
        ...newData,
        userPortalRoleId: applicableRole?.id,
        userPortalRoleName: applicableRole?.userRoleName,
      });
    }
    const userRowList = _.cloneDeep(this.state.rowData);
    const updatedUsers = userRowList.map((userData) => {
      if (userData.id === userRow.id) {
        const updatedUser = user.find((item) => item.id === userRow.id);
        const applicableRole = this.getApplicableUserRole(updatedUser);
        return {
          ...userData,
          user: {
            ...userData.user,
            userType: value,
            userPortalRoleName: applicableRole?.userRoleName,
            roleId: applicableRole?.id,
            eyServiceLine: eyServiceLine,
            eySubServiceLine: eySubServiceLine,
          },
          userType: value,
          userPortalRoleName: applicableRole?.userRoleName,
          roleId: applicableRole?.id,
          serviceLineCode: eyServiceLine?.code,
          serviceLineId: eyServiceLineId,
          subServiceLineCode: eySubServiceLine?.code,
        };
      } else {
        return userData;
      }
    });

    this.setState({
      ...this.state,
      rowData: updatedUsers,
      updatedUserData: user,
      isAdminUsersGridUpdated: true,
    });
  };

  serviceLinesChanged = (selectedOption, userRow) => {
    const { value, id, label } = selectedOption;
    if (value === userRow.oldValue) return;
    const { updatedUserData } = this.state;
    this.gridApi.forEachNodeAfterFilterAndSort((rowNode) => {
      const rowsUpdated: any[] = [];
      if (rowNode.data.id === userRow.id) {
        const data = { ...rowNode.data };
        data.serviceLineCode = value;
        data.serviceLineId = id;
        data.subServiceLineCode = null;
        rowsUpdated.push(data);
      }
      if (rowsUpdated.length > 0) {
        this.gridApi.applyTransaction({
          update: rowsUpdated,
        });
      }
    });
    const user = _.cloneDeep(updatedUserData);
    const userExist = user?.find((item) => item.id === userRow.id);
    if (userExist) {
      user.map((data) => {
        if (data.id === userRow.id) {
          data.eyServiceLine = { code: value, desc: label };
          data.eySubServiceLine = { code: null, desc: null };
        }
        return data;
      });
    } else {
      const newData = {
        id: userRow.id,
        userType: userRow?.userType,
        userPortalRoleId: userRow.roleId,
        userPortalRoleName: userRow.userPortalRoleName,
        eyServiceLine: { code: value, desc: label },
        eySubServiceLine: { code: null, desc: null },
      };
      user.push(newData);
    }
    const userRowList = _.cloneDeep(this.state.rowData);
    const updatedUsers = userRowList.map((userData) => {
      if (userData.id === userRow.id) {
        return {
          ...userData,
          serviceLineCode: value,
          serviceLineId: id,
          subServiceLineCode: null,
        };
      } else {
        return userData;
      }
    });

    this.setState({
      ...this.state,
      rowData: updatedUsers,
      updatedUserData: user,
      isAdminUsersGridUpdated: true,
    });
  };

  subServiceLinesChanged = (selectedOption, userRow) => {
    const { value, label } = selectedOption;
    const { updatedUserData } = this.state;
    this.gridApi.forEachNodeAfterFilterAndSort((rowNode) => {
      const rowsUpdated: any[] = [];
      if (rowNode.data.id === userRow.id) {
        const data = { ...rowNode.data };
        data.subServiceLineCode = value;
        rowsUpdated.push(data);
      }
      if (rowsUpdated.length > 0) {
        this.gridApi.applyTransaction({
          update: rowsUpdated,
        });
      }
    });
    const user = _.cloneDeep(updatedUserData);
    const userExist = user?.find((item) => item.id === userRow.id);
    if (userExist) {
      user.map((data) => {
        if (data.id === userRow.id) {
          data.eySubServiceLine = { code: value, desc: label };
        }
        return data;
      });
    } else {
      const newData = {
        id: userRow.id,
        userType: userRow?.userType,
        userPortalRoleId: userRow.roleId,
        userPortalRoleName: userRow.userPortalRoleName,
        eyServiceLine: {
          code: userRow.user.eyServiceLine.code,
          desc: userRow.user.eyServiceLine.desc,
        },
        eySubServiceLine: {
          code: value,
          desc: label,
        },
      };
      user.push(newData);
    }
    const userRowList = _.cloneDeep(this.state.rowData);
    const updatedUsers = userRowList.map((userData) => {
      if (userData.id === userRow.id) {
        return {
          ...userData,
          subServiceLineCode: value,
        };
      } else {
        return userData;
      }
    });

    this.setState({
      ...this.state,
      rowData: updatedUsers,
      updatedUserData: user,
      isAdminUsersGridUpdated: true,
    });
  };

  validateAdminUserType = async (value, userRow) => {
    const { warnings } = this.state;
    let warningsList = _.cloneDeep(warnings);
    this.props.validateAdminUserRequest();
    const userStatus = await validateAdminUser(userRow.id, { userType: value });
    const userTypeWarning = userStatus?.data?.responseMessages?.userType?.find(
      (msg) => (msg.type = VALIDATION_RESPONSE_TYPE.WARNING)
    );
    const warningExist = warningsList?.find(
      (item) => item.userId === userRow.id
    );
    if (warningExist) {
      if (userTypeWarning) {
        warningsList.map((data: warningDataModel) => {
          if (data.userId === userRow.id) {
            data.content = userTypeWarning.content;
          }
          return data;
        });
      } else {
        warningsList = warningsList.filter(
          (item: any) =>
            !(
              item.field === USER_GRID_FIELDS.USER_TYPE &&
              item.userId === userRow.id
            )
        );
      }
    } else {
      if (userTypeWarning) {
        const newWarning = {
          userId: userRow.id,
          content: userTypeWarning.content,
          type: userTypeWarning.type,
          field: USER_GRID_FIELDS.USER_TYPE,
        };
        warningsList.push(newWarning);
      }
    }
    this.props.validateAdminUserRequestCompleted();
    this.setState({
      ...this.state,
      warnings: warningsList,
    });
    return {
      serviceLine: userStatus.data?.serviceLine,
      subServiceLine: userStatus.data?.subServiceLine,
    };
  };

  showProjectDetails = (userRow?) => {
    if (userRow && userRow.id) {
      this.props.getAdminProjectsByUserRequest(userRow.id);
      const userName = `${userRow.givenName} ${userRow.surname}`;
      this.setState({ userName, generateProjectsModal: true });
    }
  };

  getProjectTotals = (user, adminConsoleAccess) => {
    if (user.userProjects) {
      if (adminConsoleAccess.portalAdmin) {
        return (
          <div className="link" onClick={() => this.showProjectDetails(user)}>
            {user.userProjects} {t.admin_portal_users_active}{" "}
            {t.admin_portal_user_project}
          </div>
        );
      } else {
        return (
          <div>
            {user.userProjects} {t.admin_portal_user_project}
          </div>
        );
      }
    } else {
      return "-";
    }
  };

  getApplicableUserRole = (user) => {
    const defaultUserRole = this.props.sspRoles.find((role) =>
      isPortalUser(role.id, this.props.sspRoles)
    );
    if (
      user?.userType.toUpperCase() === EXTERNAL_USER &&
      user.roleId !== defaultUserRole?.id
    ) {
      return defaultUserRole;
    } else {
      return {
        userRoleName: user.userPortalRoleName,
        id: user.userPortalRoleId,
      };
    }
  };

  generateUsersRowData = (userList) => {
    const { updatedUserData, disabledResetAll } = this.state;
    let resetButton, acceptedTerms, selectedServiceLine;
    const adminConsoleAccess = this.getUserAdminConsoleAccess();
    const updateUser = _.cloneDeep(updatedUserData);
    const rowDataValue = userList?.map((user, index) => {
      const userExist = updateUser?.find((item) => item.id === user.id);

      if (userExist) {
        resetButton =
          userExist.hasAcceptedTerms &&
          !disabledResetAll &&
          adminConsoleAccess.portalAdmin ? (
            <div className="link" onClick={() => this.resetRow(userExist)}>
              {t.admin_portal_users_reset}
            </div>
          ) : (
            ""
          );
        acceptedTerms =
          userExist.hasAcceptedTerms && !disabledResetAll
            ? this.acceptedTermText
            : this.notAcceptedTermText;
        selectedServiceLine = this.props.serviceLines?.find(
          (line) => line.code === userExist.eyServiceLine?.code
        );
      } else {
        resetButton =
          user.hasAcceptedTerms &&
          !disabledResetAll &&
          adminConsoleAccess.portalAdmin ? (
            <div className="link" onClick={() => this.resetRow(user)}>
              {t.admin_portal_users_reset}
            </div>
          ) : (
            ""
          );
        acceptedTerms =
          user.hasAcceptedTerms && !disabledResetAll
            ? this.acceptedTermText
            : this.notAcceptedTermText;
        selectedServiceLine = this.props.serviceLines?.find(
          (line) => line.code === user.eyServiceLine?.code
        );
      }

      const projectTotals = this.getProjectTotals(user, adminConsoleAccess);
      return {
        user,
        givenName: user.givenName,
        projects: projectTotals,
        accountType:
          user.accountType?.charAt(0).toUpperCase() +
            user.accountType?.slice(1).toLowerCase() || "-",
        hasAcceptedTerms: acceptedTerms,
        action: resetButton,
        actionEMG: "",
        id: user.id,
        userPortalRoleName:
          (userExist && userExist.userPortalRoleName) ||
          user.userPortalRoleName,
        roleId:
          (userExist && userExist.userPortalRoleId) || user.userPortalRoleId,
        dataIndex: index,
        totalRows: userList.length - 1,
        userType: (userExist && userExist?.userType) || user?.userType,
        serviceLineCode:
          (userExist && userExist.eyServiceLine.code) ||
          user.eyServiceLine.code,
        serviceLineId: selectedServiceLine ? selectedServiceLine.id : null,
        subServiceLineCode:
          (userExist && userExist.eySubServiceLine.code) ||
          user.eySubServiceLine.code,
      };
    });
    this.setState({
      ...this.state,
      rowData: rowDataValue,
    });
  };

  handlePageNumberChange = (pageNumber) => {
    this.handlePagination({ pageNumber });
  };

  handlePageSizeChange = (pageSize) => {
    this.handlePagination({ pageSize, pageNumber: 1 });
  };

  refreshGrid = () => {
    const { getUsersList } = this.props;
    const { paginationPayload } = this.state;

    getUsersList(paginationPayload);
  };

  handleSearchChange = (query) => {
    this.props.fetchSearchData(query);
  };

  handleSelectValue = (selectedArray) => {
    this.setState(
      {
        ...this.state,
        currentPage: 1,
        searchQuery: selectedArray,
        resetAllFilters: true,
      },
      () => {
        if (selectedArray.length > 0) {
          this.generateUsersRowData(selectedArray);
        } else {
          this.generateUsersRowData(this.props.userList);
        }
      }
    );
  };

  renderMenuItem = (option, props) => {
    const stringData = option.givenName + " " + option.surname + ", " + option.mail + `${(option.officeLocation !== null) ? `, ${option.officeLocation}` : ''}`;
    return (
      <div className="async-dropdown" title={stringData} dangerouslySetInnerHTML={{ __html: getStringWithBold(stringData, props.text) }}>
      </div>
    );
  };
  updateUserData = () => {
    this.typeahead.clear();
    const { disabledResetAll, disabledResetAllEMG, updatedUserData } =
      this.state;
    const updatedData = {
      isResetAll: disabledResetAll,
      users: updatedUserData,
      isResetEmgAll: disabledResetAllEMG,
    };

    this.props.updateData(updatedData);
    this.setState({
      disabledResetAll: false,
      disabledResetAllEMG: false,
      isAdminUsersGridUpdated: false,
      searchQuery: [],
      warnings: [],
    });
  };

  cancelUserData = () => {
    this.setState(
      {
        ...this.state,
        updatedUserData: [],
        searchQuery: [],
        disabledResetAll: false,
        disabledResetAllEMG: false,
        isAdminUsersGridUpdated: false,
        resetAllFilters: true,
        warnings: [],
      },
      () => {
        this.typeahead.clear();
      }
    );
  };

  clearSearchData = () => {
    this.setState(
      {
        searchQuery: [],
        resetAllFilters: true,
      },
      () => {
        this.typeahead.clear();
      }
    );
  };

  closeNotification = () => {
    this.setState({ showNotification: false });
  };

  manageNotification = () => {
    this.setState({ showNotification: true, updatedUserData: [] });
    setTimeout(() => {
      if (this.state.showNotification) {
        this.setState({ showNotification: false });
      }
    }, 3000);
  };

  toggleCancelDialog = (shown: boolean) => {
    const { showGridUpdateCancelDialog } = this.state;
    if (showGridUpdateCancelDialog !== shown) {
      this.setState({
        ...this.state,
        showGridUpdateCancelDialog: shown,
      });
    }
  };

  handleConfirmForRedirection = () => {
    const { history } = this.props;
    const { nextLocation } = this.state;
    history.push(nextLocation);
  };

  getUserAdminConsoleAccess = () => {
    const { accessRight, sspRoles } = this.props;
    const portalRole =
      (accessRight?.portalRoles &&
        accessRight.portalRoles.length > 0 &&
        accessRight.portalRoles[0]) ||
      "";
    return getAdminConsoleAccess(portalRole, sspRoles);
  };

  onProjectsModalClose = () => {
    this.props.flushAdminProjectByUserAction();
    this.setState({ showProjectsModal: false });
  };

  onProjectNameClick = (selectedProject: ProjectModel) => {
    window.open(
      `${window.location.origin}/project/${selectedProject.id}`,
      "_blank"
    );
  };

  onNoOfUsersClick = (selectedProject: ProjectModel) => {
    window.open(
      `${window.location.origin}/project/${selectedProject.id}/manage-users`,
      "_blank"
    );
  };

  generateProjectRowData = () => {
    const projectRowDataModel = this.props.userProjects.map(
      (project, index) => {
        const projectNameVal = project.name ? (
          project.curentContextUserProjectAccess ? (
            <AppButton
              variant="text"
              onClick={() => this.onProjectNameClick(project)}
            >
              {project.name}
            </AppButton>
          ) : (
            <span>{project.name}</span>
          )
        ) : (
          "-"
        );

        const noOfUsersVal = project.noOfProjectUsers ? (
          project.curentContextUserProjectAccess ? (
            <AppButton
              variant="text"
              onClick={() => this.onNoOfUsersClick(project)}
            >
              {project.noOfProjectUsers}
            </AppButton>
          ) : (
            <span>{project.noOfProjectUsers}</span>
          )
        ) : (
          "-"
        );

        const statusVal = project.status ? (
          <span
            className={`status-pill 
            ${
              project.status.toLowerCase() === EProjectStatuses.PND &&
              "pending-state"
            }
            ${
              project.status.toLowerCase() === EProjectStatuses.DRFT &&
              "draft-state"
            }
            ${
              (project.status.toLowerCase() === EProjectStatuses.ACTV ||
                project.status.toLowerCase() === EProjectStatuses.RDY) &&
              "ready-state"
            }
            ${
              project.status.toLowerCase() === EProjectStatuses.EROR &&
              "error-state"
            }
            `}
          >
            {project.status}
          </span>
        ) : (
          "-"
        );

        return {
          id: index,
          projectName: projectNameVal,
          type: project.projectTypeName || "-",
          dataHostingLoc: project.locationName || "-",
          noOfUsers: noOfUsersVal,
          status: statusVal,
        };
      }
    );

    this.setState({
      projectRowDataModel,
      showProjectsModal: true,
      generateProjectsModal: false,
    });
  };

  filterRoleOptionsBasedOnAccountType = (
    userDetails: UsersRowDataModel,
    options: any[]
  ) => {
    if (userDetails?.userType.toUpperCase() === EXTERNAL_USER) {
      return options.filter(
        (option) => !option.label.toLowerCase().includes("admin")
      );
    }

    return options;
  };

  handlePagination = (paginationParams) => {
    const { paginationPayload } = this.state;
    const updatedPaginationPayload = {
      ...paginationPayload,
      ...paginationParams,
    };

    this.setState({ paginationPayload: updatedPaginationPayload });
  };

  handleSorting = (
    sortColumn = DEFAULT_SORT.colId,
    sortDirection = DEFAULT_SORT.sort
  ) => {
    const { paginationPayload } = this.state;

    if (
      paginationPayload.sortColumn === sortColumn &&
      paginationPayload.sortDirection === sortDirection
    ) {
      return;
    }

    const updatedPaginationPayload = {
      ...paginationPayload,
      sortColumn,
      sortDirection,
    };
    this.setState({ paginationPayload: updatedPaginationPayload });
  };

  handleFilterChange = (
    filters,
    sortColumn = DEFAULT_SORT.colId,
    sortDirection = DEFAULT_SORT.sort
  ) => {
    const { paginationPayload } = this.state;
    const updatedPayload = {
      ...paginationPayload,
      filters,
      sortColumn,
      sortDirection,
      pageNumber: 1,
    };

    this.setState({ paginationPayload: updatedPayload });
  };

  updateFilterState = () => {
    const {
      filters = [],
      sortDirection,
      sortColumn,
    } = this.state.paginationPayload;
    const isDefaultSort =
      sortDirection === DEFAULT_SORT.sort && sortColumn === DEFAULT_SORT.colId;
    const isClearAllDisabled = isDefaultSort && !filters.length;

    this.props.updatefilterState({
      filters,
      sortDirection,
      sortColumn,
      isClearAllDisabled,
    });
  };

  filterSubServiceLineOptions = (
    userDetails: UsersRowDataModel,
    options: any[]
  ) => {
    return userDetails?.userType.toUpperCase() === EXTERNAL_USER
      ? []
      : options.filter(
          (option) => option.serviceLineId === userDetails.serviceLineId
        );
  };

  isExternalUser = (userDetails: UsersRowDataModel, options: any[]) => {
    return userDetails?.userType.toUpperCase() === EXTERNAL_USER ? [] : options;
  };

  generateClassNameOnRender = (
    userDetails: UsersRowDataModel,
    column: string
  ) => {
    let className = "";
    switch (column) {
      case USER_GRID_FIELDS.SERVICE_LINE:
      case USER_GRID_FIELDS.SUB_SERVICE_LINE: {
        if (userDetails.userType.toUpperCase() === EXTERNAL_USER)
          className = "disabled";
        break;
      }
      case USER_GRID_FIELDS.USER_TYPE: {
        const { warnings } = this.state;
        const userTypeWarning: any = warnings.find(
          (item: warningDataModel) => item.userId === userDetails.id
        );
        className = userTypeWarning && "field-data-warning";
        break;
      }
      default: {
        className = "";
        break;
      }
    }

    return className;
  };

  renderResetAllTermsHeader = () => (
    <span id="reset-all-terms" className="link" onClick={this.resetAllTerms}>
      {t.admin_portal_users_reset_all}
    </span>
  );

  renderResetEMGHeader = () => (
    <span id="reset-all-EMG" className="link" onClick={this.resetAllEMG}>
      {t.admin_portal_users_reset_all_EMG}
    </span>
  );

  renderUserTypeHeader = () => {
    const userTypeInfo = (
      <div>
        <div>{t.admin_portal_user_type_info_tooltip_line_1}</div>
        <div>{t.admin_portal_user_type_info_tooltip_line_2}</div>
        <div>{t.admin_portal_user_type_info_tooltip_line_3}</div>
        <div>{t.admin_portal_user_type_info_tooltip_line_4}</div>
        <div>{t.admin_portal_user_type_info_tooltip_line_5}</div>
        <div>{t.admin_portal_user_type_info_tooltip_line_6}</div>
        <div>{t.admin_portal_user_type_info_tooltip_line_7}</div>
        <div>{t.admin_portal_user_type_info_tooltip_line_8}</div>
        <div>{t.admin_portal_user_type_info_tooltip_line_9}</div>
        <div>{t.admin_portal_user_type_info_tooltip_line_10}</div>
      </div>
    );

    return (
      <div className="user-type-header">
        <span>User Type</span>
        <AppTooltip title={userTypeInfo} placement="top" tooltipwidth={800}>
          <AppIcon icon={info} />
        </AppTooltip>
      </div>
    );
  };

  renderUserTypeWarningTemplate = (userRow, field) => {
    const { warnings } = this.state;
    const userTypeWarning: any = warnings.find(
      (item: warningDataModel) => item.userId === userRow.id
    );

    return (
      <div className="warning-container">
        {userTypeWarning && (
          <AppTooltip
            title={userTypeWarning?.content}
            placement="top"
            tooltipwidth={800}
          >
            <AppIcon icon={warning} />
          </AppTooltip>
        )}
      </div>
    );
  };

  render() {
    const {
      paginationPayload,
      columns = [],
      rowData,
      searchQuery,
      disabledResetAllEMG,
      updatedUserData,
      showNotification,
      showGridUpdateCancelDialog,
      disabledResetAll,
      showProjectsModal,
      projectRowDataModel,
      userName,
      resetAllFilters,
    } = this.state;
    const adminConsoleAccess = this.getUserAdminConsoleAccess();
    const isUserSearched = !!(searchQuery && searchQuery.length);

    const { searchLoader, adminUserSearchUserGrid, pagination } = this.props;

    if (pagination) {
      paginationPayload.totalRecords = pagination.totalRecords;
    }

    return (
      <CrashBoundary>
        <div className="admin-users">
          <div>
            <div>
              <p className="admin-users-title">
                {t.admin_menu_header_users_title}
              </p>
              <div className="users-table">
                <div className="search-wrapper">
                  <div className="project-form-field">
                    <AppIcon icon={search} />
                    <AsyncTypeahead
                      id="async-search"
                      isLoading={searchLoader}
                      filterBy={() => true}
                      labelKey={(option) =>
                        `${option.givenName} ${option.surname}`
                      }
                      minLength={3}
                      onSearch={this.handleSearchChange}
                      options={adminUserSearchUserGrid}
                      onChange={(selected) => this.handleSelectValue(selected)}
                      placeholder={t.admin_user_search_user}
                      ref={(ref) => (this.typeahead = ref)}
                      useCache={false}
                      renderMenuItemChildren={(option, props) =>
                        this.renderMenuItem(option, props)
                      }
                    >
                      {({ selected }) => (
                        <div className="rbt-aux">
                          {!!selected.length && (
                            <ClearButton
                              onClick={() => this.clearSearchData()}
                            />
                          )}
                        </div>
                      )}
                    </AsyncTypeahead>
                  </div>
                </div>
                <div className={`admin-users-table`}>
                  <List
                    rowHeight={72}
                    columnConfig={columns}
                    firstColumnBorderRight={true}
                    rowData={rowData}
                    paginate={true}
                    withStickyScroll
                    onGridReady={this.onGridReady}
                    onSortChanged={this.handleSorting}
                    currentPage={
                      isUserSearched ? 1 : paginationPayload.pageNumber
                    }
                    pagination={
                      isUserSearched
                        ? getPaginationModel({
                            ...getDefaultPaginationModel(),
                            ...paginationPayload,
                            pageNumber: 1,
                            totalRecords: rowData.length,
                          })
                        : paginationPayload
                    }
                    setItemsPerPage={this.handlePageSizeChange}
                    onPageChange={this.handlePageNumberChange}
                    onFilterChange={this.handleFilterChange}
                    defaultSort={[DEFAULT_SORT]}
                    resetAllFilters={resetAllFilters}
                    frameworkComponents={{
                      resetAllHeader: this.renderResetAllTermsHeader,
                      resetEMGHeader: this.renderResetEMGHeader,
                      userTypeHeader: this.renderUserTypeHeader,
                    }}
                  />
                </div>
              </div>
            </div>
            {adminConsoleAccess.portalAdmin && (
              <div>
                <div className="user-actions">
                  <AppButton
                    size="large"
                    className="create-btn"
                    disabled={
                      !updatedUserData.length &&
                      !disabledResetAll &&
                      !disabledResetAllEMG
                    }
                    color="primary"
                    onClick={() => this.updateUserData()}
                  >
                    {t.update}
                  </AppButton>
                  <AppButton
                    size="large"
                    className="cancel-btn"
                    color="secondary"
                    onClick={() => this.cancelUserData()}
                  >
                    {t.cancel}
                  </AppButton>
                </div>
              </div>
            )}
            {showNotification && (
              <AppNotification
                message={t.admin_user_success_message}
                variant={"success"}
                onCloseNotifcation={this.closeNotification}
              />
            )}

            <AppModal
              size="sm"
              showModal={showGridUpdateCancelDialog}
              confirmBtnText={t.manage_users_cancel_continue_btn}
              onConfirm={this.handleConfirmForRedirection}
              cancelBtnText={t.cancel}
              confirmBtnClass="warning"
              onModalClose={() => this.toggleCancelDialog(false)}
              title={t.manage_users_cancel_dialog_title}
            >
              {t.manage_users_cancel_redirect_message}
            </AppModal>

            {showProjectsModal && projectRowDataModel.length && (
              <AdminProjectsAppsModal
                showModal={showProjectsModal}
                onAppsModalClose={this.onProjectsModalClose}
                ProjectName={userName}
                projectRowDataModel={projectRowDataModel}
              />
            )}
          </div>
        </div>
      </CrashBoundary>
    );
  }
}

const mapStateToProps = ({
  meReducer,
  meta,
  adminUsersReducer,
  adminProjectsReducer,
}) => {
  return {
    accessRight: meReducer.accessRight,
    sspRoles: meta.userRoles,
    userList: adminUsersReducer.users,
    pagination: adminUsersReducer.userGridPagination,
    searchLoader: adminUsersReducer.searchLoader,
    adminUserSearchUserGrid: adminUsersReducer.adminSearchUserGrid.data,
    updateAdminUserGrid: adminUsersReducer.updateData,
    isAnyoneHasAcceptedTerms: adminUsersReducer.isAnyoneHasAcceptedTerms,
    userProjects: adminProjectsReducer.userProjects,
    serviceLines: meta.serviceLines,
    subServiceLines: meta.subServiceLines,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUsersList: (payload) => {
      dispatch(getUsersList(payload));
    },
    fetchSearchData: (payload) => {
      dispatch(searchAdminUserGrid(payload));
    },
    updateData: (payload) => {
      dispatch(updateUserGrid(payload));
    },
    getAdminProjectsByUserRequest: (payload) => {
      dispatch(getAdminProjectsByUserRequest(payload));
    },
    flushAdminProjectByUserAction: () => {
      dispatch(flushAdminProjectByUserAction());
    },
    updatefilterState: (payload) => {
      dispatch(updatefilterState(payload));
    },
    validateAdminUserRequest: () => {
      dispatch(validateAdminUserRequest());
    },
    validateAdminUserRequestCompleted: () => {
      dispatch(validateAdminUserSuccess());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminUsers)
);
