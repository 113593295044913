import React, { FC } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  ListItemText,
} from "@material-ui/core";
import themeConfig from "../config";
import "./motifMultiAppSelect.scss";
import MotifAppCheckbox from "../../theme/motifAppCheckbox/motifAppCheckbox";
import AppIcon from "../appIcon/appIcon";
import { angleDown } from "../icons";
const useStyle = makeStyles({
  root: {
    width: "100%",
    fontFamily: themeConfig.eyInterstateLight,
    color: themeConfig.gray,
  },
  select: {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
});

const ClickableIcon = ({ style }) => (
  <div style={{right: style.right}} className="dropdown-icon">
    <AppIcon icon={angleDown}  />
  </div>
);

interface ISelectProps {
  id?: string;
  value?: string;
  name?: string;
  isValid?: boolean;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  options: Array<{ value: string | number; label: string }>;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  className?: string;
  startAdornment?: any;
  endAdornment?: any;
  showWarning?: boolean;
  isRequired?: boolean;
  multiple?: boolean;
  displayEmpty?: boolean;
  emptyValue?: string;
  renderValue?: (selected: any) => React.ReactNode;
  MenuProps?: any;
  renderOption?: (option: any) => React.ReactNode;
  inputProps?: any;
}

const MotifMultiAppSelect: FC<ISelectProps> = ({
  value,
  isValid,
  options,
  startAdornment,
  endAdornment,
  className,
  label,
  showWarning = false,
  isRequired,
  multiple = false,
  emptyValue = "",
  placeholder = "Select", // Default placeholder
  ...restProps
}) => {
  const classes = useStyle();

  const startAdornmentElement = startAdornment ? (
    <InputAdornment position="start">{startAdornment}</InputAdornment>
  ) : null;

  const endAdornmentElement = endAdornment ? (
    <InputAdornment position="end">{endAdornment}</InputAdornment>
  ) : null;
  const selectValue = multiple ? (Array.isArray(value) ? value : []) : value;
  return (
    <FormControl variant="outlined" classes={{ root: classes.root }} fullWidth>
      <div className="select-wrapper multi-app-select">
        <span className="input-label">{label}</span>
        {isRequired ? <span className="asterisk">*</span> : null}
        <Select
          className={`motif-select ${
            className || endAdornmentElement ? "shift-caret" : ""
          } ${isValid !== undefined && !isValid ? "in-valid" : ""} 
          ${showWarning ? "show-warning" : ""}`}
          displayEmpty
          emptyValue={emptyValue}
          multiple={multiple} // Enable multiple selections
          value={multiple ? value || [] : value} // Ensure value is an array if multiple is true
          IconComponent={() => (
            <ClickableIcon style={{ right: endAdornment ? "30px" : "10px" }}/>
          )}
          renderValue={(selected) => {
            if (multiple) {
              return (selected as string[]).length === 0
                ? placeholder
                : (selected as string[]).join(", ");
            }
            return selected || placeholder;
          }}
          {...restProps}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {options.length < 1 ? (
            <option value="" disabled>
              {emptyValue}
            </option>
          ) : null}
          {options.map((option) => (
            <MenuItem key={option} value={option} disabled={option === ""}>
              {multiple && (
                <MotifAppCheckbox checked={selectValue.indexOf(option) > -1} />
              )}
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
        {startAdornmentElement && (
          <span className="start-tooptip">{startAdornmentElement}</span>
        )}
        {endAdornmentElement && (
          <span className="end-tooltip">{endAdornmentElement}</span>
        )}
      </div>
    </FormControl>
  );
};

export default MotifMultiAppSelect;
