import AppModal from "../../theme/appModal/appModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import en_translation from '../../localization/en/translation.json';
import "./emailMultipleMailStyle.scss";
import { useSelector } from "react-redux";
import { APP_STATE } from "../../store/reducers";
import { ME_REDUCER_STATE } from "../../store/reducers/me-reducer";
import { AppButton } from "../../theme";

export const MultipleEmailChangeModalPopUp = () => {
  const { currentEmail,previousEmail, isMailChangeSuccess} = useSelector<
  APP_STATE,
  ME_REDUCER_STATE
>(({ meReducer }) => meReducer);
const DataToReplace = {
  currentEmail,
  previousEmail,
};
  return (
    <AppModal
      size="md"
      modalClass="emailChange"
      showModal={true}
      showCloseModelIcon={false}
    >
      <div className="modal-content">
        <h3>{en_translation.multiple_email_popup_title}</h3>
        <div>
          <p dangerouslySetInnerHTML={{ __html: en_translation.multiple_email_popup_content.replace(/currentEmail|previousEmail/gi, (matched)=> `<strong> <u> ${DataToReplace[matched]} </u> </strong>`)}}></p>
          <p>{en_translation.multiple_email_popup_content_line1}</p>
        </div>
        <br />
        <br />
        <div className="progressEmailChange">
        {isMailChangeSuccess ? (
            <AppButton onClick={() => window.location.reload()}>{en_translation.ok}</AppButton>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
    </AppModal>
  );
};
