import React, { FC } from 'react';
import { CircularProgress, CircularProgressProps } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import theme from "../../theme/config";
import './loader.scss';

const useStyle = makeStyles({
    root: {
        color: theme.buttonPrimaryBgColor
    }
});

interface LoaderProps extends CircularProgressProps {
    isLoading: boolean;
    isFullSize?: boolean;
}

const Loader: FC<LoaderProps> = ({ isLoading, isFullSize=true, ...props }) => {
    const classes = useStyle();

    return (
        isLoading ? <div className={isFullSize ? "app-loader": "container-loader"}>
            <CircularProgress classes={{ ...classes }} {...props} />
        </div> : null
    );
}

export default Loader;
