import axios, { CancelTokenSource } from "axios";
import {
  REQUEST_TYPE,
  API_URL,
  createAxiosRequest,
  createAxiosRequestMultiPartData,
} from "../api";
import { AppPublishStatus } from "../../components/appCard/models/appPublishStatus";

export const getMe = () => {
  const GET_PROJECT_TYPES_ENDPOINT = `${API_URL}/api/v1/me`;
  return createAxiosRequest(REQUEST_TYPE.GET, GET_PROJECT_TYPES_ENDPOINT);
};

export const fetchProjectTypes = () => {
  const GET_PROJECT_TYPES_ENDPOINT = `${API_URL}/api/v1/select/projecttypes`;
  return createAxiosRequest(REQUEST_TYPE.GET, GET_PROJECT_TYPES_ENDPOINT);
};

export const updateProjectType = (action) => {
  const { id } = action.payload;
  const UPDATE_PROJECT_TYPE_ENDPOINT = `${API_URL}/api/v1/select/${id}/projecttypes`;
  return createAxiosRequest(
    REQUEST_TYPE.POST,
    UPDATE_PROJECT_TYPE_ENDPOINT,
    action.payload
  );
};

export const addProjectType = (action) => {
  const ADD_PROJECT_TYPE_ENDPOINT = `${API_URL}/api/v1/select/projecttypes`;
  return createAxiosRequest(
    REQUEST_TYPE.POST,
    ADD_PROJECT_TYPE_ENDPOINT,
    action.payload
  );
};

export const fetchPricingQuestions = () => {
  const GET_ENDPOINT = `${API_URL}/api/v1/select/PricingQuestionsAnswers`;
  return createAxiosRequest(REQUEST_TYPE.GET, GET_ENDPOINT);
};

export const fetchSectors = () => {
  const GET_SECTORS_ENDPOINT = `${API_URL}/api/v1/select/sectors`;
  return createAxiosRequest(REQUEST_TYPE.GET, GET_SECTORS_ENDPOINT);
};

export const fetchClientSizes = () => {
  const GET_CLIENT_SIZES_ENDPOINT = `${API_URL}/api/v1/select/clientsizes`;
  return createAxiosRequest(REQUEST_TYPE.GET, GET_CLIENT_SIZES_ENDPOINT);
};

export const fetchDataLocations = () => {
  const GET_DATA_LOCATIONS_ENDPOINT = `${API_URL}/api/v1/select/locations`;
  return createAxiosRequest(REQUEST_TYPE.GET, GET_DATA_LOCATIONS_ENDPOINT);
};

export const fetchProjectcategories = () => {
  const GET_PROJECT_CATEGORIES_ENDPOINT = `${API_URL}/api/v1/select/projectcategories`;
  return createAxiosRequest(REQUEST_TYPE.GET, GET_PROJECT_CATEGORIES_ENDPOINT);
};

export const fetchNotifications = (type) => {
  const ADMIN_NOTIFICATION_URI = `${API_URL}/api/v1/admin/notifications?type=${type}`;
  return createAxiosRequest(REQUEST_TYPE.GET, ADMIN_NOTIFICATION_URI);
};

export const addNotification = (notification) => {
  const ADMIN_NOTIFICATION_URI = `${API_URL}/api/v1/admin/notifications`;
  return createAxiosRequest(
    REQUEST_TYPE.POST,
    ADMIN_NOTIFICATION_URI,
    notification
  );
};

export const updateNotification = (notification) => {
  const ADMIN_NOTIFICATION_URI = `${API_URL}/api/v1/admin/notifications`;
  return createAxiosRequest(
    REQUEST_TYPE.PUT,
    ADMIN_NOTIFICATION_URI,
    notification
  );
};

export const deleteNotification = ({ notificationId }) => {
  const ADMIN_NOTIFICATION_URI = `${API_URL}/api/v1/admin/notifications/${notificationId}`;
  return createAxiosRequest(REQUEST_TYPE.DELETE, ADMIN_NOTIFICATION_URI);
};

export const fetchAdminProjects = (action) => {
  const GET_ADMIN_PROJECTS_ENDPOINT = `${API_URL}/api/v1/Admin/projects`;
  return createAxiosRequest(
    REQUEST_TYPE.POST,
    GET_ADMIN_PROJECTS_ENDPOINT,
    action.payload
  );
};

export const updateAdminProject = (action) => {
  const UPDATE_ADMIN_PROJECT_ENDPOINT = `${API_URL}/api/v1/Admin/projects/${action.payload.id}`;
  return createAxiosRequest(
    REQUEST_TYPE.PUT,
    UPDATE_ADMIN_PROJECT_ENDPOINT,
    action.payload
  );
};

export const deleteAdminProject = ({ payload: { id, feedback } }) => {
  const DELETE_ADMIN_PROJECT_ENDPOINT = `${API_URL}/api/v1/Admin/projects/delete/${id}`;
  return createAxiosRequest(
    REQUEST_TYPE.DELETE,
    DELETE_ADMIN_PROJECT_ENDPOINT,
    feedback
  );
};

export const fetchProjectStatus = () => {
  const GET_PROJECT_STATUS_ENDPOINT = `${API_URL}/api/v1/select/ProjectStatuses`;
  return createAxiosRequest(REQUEST_TYPE.GET, GET_PROJECT_STATUS_ENDPOINT);
};

export const createProject = (action: any) => {
  const CREATE_PROJECT_ENDPOINT = `${API_URL}/api/v1/projects`;
  return createAxiosRequest(
    REQUEST_TYPE.POST,
    CREATE_PROJECT_ENDPOINT,
    action.payload
  );
};

export const updateProject = (action: any) => {
  const { id } = action.payload;
  const UPDATE_PROJECT_ENDPOINT = `${API_URL}/api/v1/projects/${id}`;
  return createAxiosRequest(
    REQUEST_TYPE.PUT,
    UPDATE_PROJECT_ENDPOINT,
    action.payload
  );
};

export const getProjectList = () => {
  const url = `${API_URL}/api/v1/projects`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};

export const getProjectsSummary = () => {
  const url = `${API_URL}/api/v1/projects/summary`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};

export const getProjectRecord = ({ id }) => {
  const url = `${API_URL}/api/v1/projects/${id}`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};

export const getProjectRecordOverridden = ({ id }) => {
  const url = `${API_URL}/api/v1/projects/${id}?includeSoftClosedProject=true`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};

export const getProjectMetaData = ({ id }) => {
  const url = `${API_URL}/api/v1/projects/${id}/metadata`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};

export const deleteDraftProject = ({ payload: draftId }) => {
  const url = `${API_URL}/api/v1/projects/${draftId}`;
  return createAxiosRequest(REQUEST_TYPE.DELETE, url);
};

export const getProjectSupportedFeatures = ({ id, key }) => {
  const url = `${API_URL}/api/v1/projects/${id}/isfeaturesupported/${key}`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};

export const updateProjectAppPublishedStatus = (payload: AppPublishStatus) => {
  const url = `${API_URL}/api/v1/applications/publish`;
  return createAxiosRequest(REQUEST_TYPE.POST, url, payload);
};

//to do - remove the data from api to pagination
export const getProjectUsers = ({
  projectId,
  userIds,
  pageNumber,
  pageSize,
}) => {
  const url = `${API_URL}/api/v1/users/${projectId}`;
  return createAxiosRequest(REQUEST_TYPE.POST, url, {
    userIds: userIds || [],
    paging: {
      totalRecords: 0,
      pageSize: pageSize,
      pageNumber: pageNumber,
      sortColumn: "givenName",
      sortDirection: "asc",
    },
  });
};

//to do - remove the data from api to pagination
export const getProjectUserAccess = ({
  projectId,
  userIds,
  pageNumber,
  pageSize,
}) => {
  const url = `${API_URL}/api/v1/users/accessrights/${projectId}`;
  return createAxiosRequest(REQUEST_TYPE.POST, url, {
    userIds: userIds || [],
    paging: {
      totalRecords: 0,
      pageSize: pageSize,
      pageNumber: pageNumber,
      sortColumn: "givenName",
      sortDirection: "asc",
    },
  });
};

export const searchManageUser = ({ projectId, email }) => {
  const MANAGE_SEARCH_USER_ENDPOINT = `${API_URL}/api/v1/users/searchdirectory/${projectId}`;
  return createAxiosRequest(
    REQUEST_TYPE.POST,
    MANAGE_SEARCH_USER_ENDPOINT,
    email
  );
};

export const searchManageUserGrid = (action: any) => {
  const MANAGE_SEARCH_USER_GRID_ENDPOINT = `${API_URL}/api/v1/users/${action.payload.id}/search?searchTerm=${action.payload.searchString}`;
  return createAxiosRequest(REQUEST_TYPE.GET, MANAGE_SEARCH_USER_GRID_ENDPOINT);
};

export const addUser = ({ projectId, body }) => {
  const URL = `${API_URL}/api/v1/users/create/${projectId}`;
  return createAxiosRequest(REQUEST_TYPE.POST, URL, body);
};

export const updateUsers = ({ body }) => {
  const URL = `${API_URL}/api/v1/users/update/${body.projectId}`;
  return createAxiosRequest(REQUEST_TYPE.POST, URL, body);
};

export const searchUsers = (action) => {
  const SEARCH_USERS_ENDPOINT = `${API_URL}/api/v1/users/search?isPpeddUser=true`;
  return createAxiosRequest(
    REQUEST_TYPE.POST,
    SEARCH_USERS_ENDPOINT,
    action.payload
  );
};

export const extendProjectApiCall = ({
  payload,
}: {
  payload: {
    id: string;
    extendedAdditionalInfo: string;
    extendedPpeddEmail: string;
  };
}) => {
  const url = `${API_URL}/api/v1/Admin/projects/extend/${payload.id}`;
  return createAxiosRequest(REQUEST_TYPE.PATCH, url, {
    extendedAdditionalInfo: payload.extendedAdditionalInfo,
    extendedPpeddEmail: payload.extendedPpeddEmail,
  });
};

export const softDeleteProjectApiCall = (projectId: string) => {
  const url = `${API_URL}/api/v1/Admin/projects/${projectId}/status/soft-close`;
  return createAxiosRequest(REQUEST_TYPE.PATCH, url);
};

export const reactivateProjectApiCall = (projectId: string) => {
  const url = `${API_URL}/api/v1/Admin/projects/${projectId}/status/reactivate`;
  return createAxiosRequest(REQUEST_TYPE.PATCH, url);
};

export const markCloseProject = ({ payload }) => {
  const url = `${API_URL}/api/v1/projects/close/${payload.id}`;
  return createAxiosRequest(REQUEST_TYPE.PATCH, url, payload.feedback);
};

export const patchProject = ({ projectId, ...rest }) => {
  const url = `${API_URL}/api/v1/projects/${projectId}`;
  return createAxiosRequest(REQUEST_TYPE.PATCH, url, rest);
};
export const getProjectClosureDetails = ({ projectId }) => {
  const url = `${API_URL}/api/v1/projects/basedata/${projectId}`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
}
export const createProjectAnnouncement = (payload) => {
  const URL = `${API_URL}/api/v1/announcements`;
  return createAxiosRequest(REQUEST_TYPE.POST, URL, payload);
};

export const getUserPaansStatus = (payload: any) => {
  const URL = `${API_URL}/api/v1/paans/policies/check`;
  return createAxiosRequest(REQUEST_TYPE.GET, URL, payload);
};

export const getProjectAnnouncements = ({
  projectId,
  userId
}) => {
  const URL = `${API_URL}/api/v1/users/${userId}/projects/${projectId}/announcements`
  return createAxiosRequest(REQUEST_TYPE.GET, URL);
};

export const getMyAccessRight = (payload: any) => {
  const URL = `${API_URL}/api/v1/users/myaccessrights`;
  return createAxiosRequest(REQUEST_TYPE.GET, URL);
};

export const getMyProjectRights = ({ projectId }) => {
  const url = `${API_URL}/api/v1/users/myprojectrights/${projectId}`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};

export const updateProjectAnnouncement = (payload) => {
  const URL = `${API_URL}/api/v1/announcements/${payload.id}`;
  return createAxiosRequest(REQUEST_TYPE.PUT, URL, payload);
};

export const deleteProjectAnnouncement = ({ projectId, announcementId }) => {
  const URL = `${API_URL}/api/v1/announcements/${projectId}/${announcementId}`;
  return createAxiosRequest(REQUEST_TYPE.DELETE, URL);
};

export const readProjectAnnouncement = ({ projectId, userId, data }) => {
  const URL = `${API_URL}/api/v1/users/${userId}/projects/${projectId}/announcements  `;
  return createAxiosRequest(REQUEST_TYPE.PUT, URL, data);
};

export const fetchProjectAreas = () => {
  const url = `${API_URL}/api/v1/select/areas`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};

export const fetchProjectRegions = () => {
  const url = `${API_URL}/api/v1/select/Regions`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};

export const fetchProjectCountries = () => {
  const url = `${API_URL}/api/v1/select/countries`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};

export const updateProjectApps = (action) => {
  const UPDATE_PROJECT_APPS_ENDPOINT = `${API_URL}/api/v1/Admin/applications`;
  return createAxiosRequest(
    REQUEST_TYPE.PUT,
    UPDATE_PROJECT_APPS_ENDPOINT,
    action.payload
  );
};

export const addProjectApps = (action) => {
  const ADD_PROJECT_APPS_ENDPOINT = `${API_URL}/api/v1/Admin/applications`;
  return createAxiosRequest(
    REQUEST_TYPE.POST,
    ADD_PROJECT_APPS_ENDPOINT,
    action.payload
  );
};

export const getAllPowerBiReports = () => {
  const url = `${API_URL}/api/v1/Admin/reportsAll`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};

export const getPowerBiReportToken = (action) => {
  const url = `${API_URL}/api/v1/Admin/reports/embedtoken/${action.payload.id}`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};

export const getAdminReports = () => {
  const url = `${API_URL}/api/v1/Admin/reports`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};

export const addAdminReport = (action) => {
  const url = `${API_URL}/api/v1/Admin/reports`;
  return createAxiosRequest(REQUEST_TYPE.POST, url, action.payload);
};

export const updateAdminReport = (action) => {
  const url = `${API_URL}/api/v1/Admin/reports/${action.payload.id}`;
  return createAxiosRequest(REQUEST_TYPE.PUT, url, action.payload);
};

export const deleteAdminReport = (action) => {
  const url = `${API_URL}/api/v1/Admin/reports/${action.payload.id}`;
  return createAxiosRequest(REQUEST_TYPE.DELETE, url);
};

export const getUsersList = (action) => {
  const ADMIN_USERS_ENDPOINT = `${API_URL}/api/v1/admin/users`;
  return createAxiosRequest(REQUEST_TYPE.POST, ADMIN_USERS_ENDPOINT, action);
};

export const fetchAdminApps = (action) => {
  const GET_ADMIN_PROJECTS_ENDPOINT = `${API_URL}/api/v1/Admin/applications/getall`;
  return createAxiosRequest(
    REQUEST_TYPE.POST,
    GET_ADMIN_PROJECTS_ENDPOINT,
    action.payload
  );
};

export const deleteAdminApp = ({ payload: appId }) => {
  const DELETE_ADMIN_APP_ENDPOINT = `${API_URL}/api/v1/Admin/applications?id=${appId}`;
  return createAxiosRequest(REQUEST_TYPE.DELETE, DELETE_ADMIN_APP_ENDPOINT);
};

export const getAdminProject = (action) => {
  const ADMIN_SEARCH_PROJECT_ENDPOINT = `${API_URL}/api/v1/Admin/projects/search/${action.payload}`;
  return createAxiosRequest(REQUEST_TYPE.GET, ADMIN_SEARCH_PROJECT_ENDPOINT);
};

// TODO- we need to apply this cancel behaviour to all search api to race condition among queries
let fetchProjectCancelToken: CancelTokenSource;
export const fetchProject = (action) => {
  if (fetchProjectCancelToken) {
    fetchProjectCancelToken.cancel();
  } else {
    fetchProjectCancelToken = axios.CancelToken.source();
  }
  const SEARCH_PROJECT_ENDPOINT = `${API_URL}/api/v1/paces`;
  return createAxiosRequest(REQUEST_TYPE.POST, SEARCH_PROJECT_ENDPOINT, {
    ...action.payload,
    cancelToken: fetchProjectCancelToken.token,
  });
};

export const patchAdditionalPaceIdsToProject = ({
  projectId,
  additionalPaceIds,
}) => {
  const PATCH_PROJECT_ENDPOINT = `${API_URL}/api/v1/Admin/projects/${projectId}`;
  return createAxiosRequest(REQUEST_TYPE.PATCH, PATCH_PROJECT_ENDPOINT, {
    additionalPaceIds,
  });
};

export const searchAdminUserGrid = (action) => {
  const ADMIN_SEARCH_USER_GRID_ENDPOINT = `${API_URL}/api/v1/Admin/users/search?searchTerm=${action.payload}`;
  return createAxiosRequest(REQUEST_TYPE.GET, ADMIN_SEARCH_USER_GRID_ENDPOINT);
};

export const appErrorHandler = (errorCode) => {
  const ERROR_HANDLER_ENDPOINT = `${API_URL}/api/v1/errors?errorcode=${errorCode}`;
  return createAxiosRequest(REQUEST_TYPE.GET, ERROR_HANDLER_ENDPOINT);
};

export const updateAdminUserGrid = (action) => {
  const UPDATE_ADMIN_USER_GRID_ENDPOINT = `${API_URL}/api/v1/Admin/users`;
  return createAxiosRequest(
    REQUEST_TYPE.PUT,
    UPDATE_ADMIN_USER_GRID_ENDPOINT,
    action.payload
  );
};

export const addAdminAppVideo = (action) => {
  const ADD_ADMIN_APPS_VIDEO_ENDPOINT = `${API_URL}/api/v1/Admin/applications/upload`;
  return createAxiosRequestMultiPartData(
    REQUEST_TYPE.POST,
    ADD_ADMIN_APPS_VIDEO_ENDPOINT,
    action.payload
  );
};

export const addAdminAppThumbnail = (action) => {
  const ADD_ADMIN_APPS_THUMBNAIL_ENDPOINT = `${API_URL}/api/v1/Admin/applications/upload`;
  return createAxiosRequestMultiPartData(
    REQUEST_TYPE.POST,
    ADD_ADMIN_APPS_THUMBNAIL_ENDPOINT,
    action.payload
  );
};

export const getAdminProjectLog = (action) => {
  const { id, data } = action.payload;
  const GET_ADMIN_PROJECT_LOG_ENDPOINT = `${API_URL}/api/v1/Admin/projects/applogs/${id}`;
  return createAxiosRequest(
    REQUEST_TYPE.POST,
    GET_ADMIN_PROJECT_LOG_ENDPOINT,
    data
  );
};

export const deleteAdminAppVideo = (action) => {
  const DELETE_ADMIN_APPS_VIDEO_ENDPOINT = `${API_URL}/api/v1/Admin/applications/deletefile`;
  return createAxiosRequest(
    REQUEST_TYPE.POST,
    DELETE_ADMIN_APPS_VIDEO_ENDPOINT,
    action.payload
  );
};

export const deleteAdminAppThumbnail = (action) => {
  const DELETE_ADMIN_APPS_THUMBNAIL_ENDPOINT = `${API_URL}/api/v1/Admin/applications/deletefile`;
  return createAxiosRequest(
    REQUEST_TYPE.POST,
    DELETE_ADMIN_APPS_THUMBNAIL_ENDPOINT,
    action.payload
  );
};

export const fetchAdminProjectsByUser = ({ payload: userId }) => {
  const ADMIN_PROJECTS_BY_USER_ID_ENDPOINT = `${API_URL}/api/v1/Admin/projects/${userId}`;
  return createAxiosRequest(
    REQUEST_TYPE.GET,
    ADMIN_PROJECTS_BY_USER_ID_ENDPOINT
  );
};
export const getAdminBanner = ({ payload }) => {
  const UPDATE_ADMIN_USER_GRID_ENDPOINT = `${API_URL}/api/v1/Admin/maintenance/${payload}`;
  return createAxiosRequest(REQUEST_TYPE.GET, UPDATE_ADMIN_USER_GRID_ENDPOINT);
};

export const postAdminBanner = ({ payload }) => {
  const UPDATE_ADMIN_USER_GRID_ENDPOINT = `${API_URL}/api/v1/Admin/maintenance`;
  return createAxiosRequest(
    REQUEST_TYPE.POST,
    UPDATE_ADMIN_USER_GRID_ENDPOINT,
    payload
  );
};

export const fetchAdminAppsSearch = (action) => {
  const ADMIN_SEARCH_APP_ENDPOINT = `${API_URL}/api/v1/Admin/applications/search/${action.payload}`;
  return createAxiosRequest(REQUEST_TYPE.GET, ADMIN_SEARCH_APP_ENDPOINT);
};

export const getBulkTemplate = () => {
  const GET_BULK_TEMPLATE_ENDPOINT = `${API_URL}/api/v1/users/downloadtemplate`;
  return createAxiosRequest(REQUEST_TYPE.GET, GET_BULK_TEMPLATE_ENDPOINT);
};

export const getAcceptedPolicies = (action) => {
  const GET_ACCEPTED_POLICY_ENDPOINT = `${API_URL}/api/v1/paans/policies/accept`;
  return createAxiosRequest(
    REQUEST_TYPE.POST,
    GET_ACCEPTED_POLICY_ENDPOINT,
    action.payload
  );
};

export const uploadBulkUserFile = (action) => {
  const URL = `${API_URL}/api/v1/users/bulkupload/${action.payload.projectId}/${
      action.payload.saveEnabled || "false"
    }`;
  return createAxiosRequestMultiPartData(
    REQUEST_TYPE.POST,
    URL,
    action.payload.formData
  );
};

export const getEMGPolicy = (action) => {
  const URL = `${API_URL}/api/v1/paans/policies/${action.payload.projectId}/emg`;
  return createAxiosRequest(REQUEST_TYPE.GET, URL, action.payload.formData);
};

export const accpetEMGPolicy = (action) => {
  const URL = `${API_URL}/api/v1/paans/policies/${action.payload.projectId}/emg/accept`;
  return createAxiosRequest(REQUEST_TYPE.POST, URL, action.payload.formData);
};

export const getWrikeSyncSettings = (id) => {
  const URL = `${API_URL}/api/v1/projects/WrikeSyncSettings/${id}`;
  return createAxiosRequest(REQUEST_TYPE.GET, URL);
};

export const updateWrikeSyncSettings = ({ id, ...payload }) => {
  const URL = `${API_URL}/api/v1/projects/WrikeSyncSettings/${id}`;
  return createAxiosRequest(REQUEST_TYPE.POST, URL, payload);
};

export const getProjectDefaultRoles = ({ projectId }) => {
  const URL = `${API_URL}/api/v1/AppPlatform/defaultroles/${projectId}`;
  return createAxiosRequest(REQUEST_TYPE.GET, URL);
};

export const enrollUserToDemoProject = ({ projectId, data }) => {
  const URL = `${API_URL}/api/v1/users/demoprojects/enroll/${projectId}`;
  return createAxiosRequest(REQUEST_TYPE.POST, URL, data);
};

export const updateCustomizationInfo = ({ projectId, data }) => {
  const URL = `${API_URL}/api/v1/Admin/projects/customizationinfo/${projectId}`;
  return createAxiosRequest(REQUEST_TYPE.PUT, URL, data);
};

export const getWrikeToggleSyncStatus = (projectId) => {
  const URL = `${API_URL}/api/v1/projects/IsValidToggleSync/${projectId}`;
  return createAxiosRequest(REQUEST_TYPE.GET, URL);
};

export const getExportProjectList = ({ payload }) => {
  const url = `${API_URL}/api/v1/Admin/projects/export`;
  return createAxiosRequest(REQUEST_TYPE.POST, url, payload);
};

export const getAdminRegions = ({ payload }) => {
  const url = `${API_URL}/api/v1/Admin/general/regions`;
  return createAxiosRequest(REQUEST_TYPE.POST, url, payload);
};

export const updateAdminRegions = ({ payload }) => {
  const url = `${API_URL}/api/v1/Admin/general/regions`;
  return createAxiosRequest(REQUEST_TYPE.PUT, url, payload);
};

export const getAdminClientSizes = ({ payload }) => {
  const url = `${API_URL}/api/v1/Admin/general/clientsizes`;
  return createAxiosRequest(REQUEST_TYPE.POST, url, payload);
};

export const updateAdminClientSizes = ({ payload }) => {
  const url = `${API_URL}/api/v1/Admin/general/clientsizes`;
  return createAxiosRequest(REQUEST_TYPE.PUT, url, payload);
};

export const getAdminSectors = ({ payload }) => {
  const url = `${API_URL}/api/v1/Admin/general/sectors`;
  return createAxiosRequest(REQUEST_TYPE.POST, url, payload);
};

export const updateAdminSectors = ({ payload }) => {
  const url = `${API_URL}/api/v1/Admin/general/sectors`;
  return createAxiosRequest(REQUEST_TYPE.PUT, url, payload);
};

export const getAdminDataHostingLoc = ({ payload }) => {
  const url = `${API_URL}/api/v1/Admin/general/locations`;
  return createAxiosRequest(REQUEST_TYPE.POST, url, payload);
};

export const getAdminTypes = ({ payload }) => {
  const url = `${API_URL}/api/v1/Admin/general/projecttypes`;
  return createAxiosRequest(REQUEST_TYPE.POST, url, payload);
};

export const updateAdminTypes = ({ payload }) => {
  const url = `${API_URL}/api/v1/Admin/general/projecttypes`;
  return createAxiosRequest(REQUEST_TYPE.PUT, url, payload);
};

export const getAdminSchedules = () => {
  const url = `${API_URL}/api/v1/Admin/maintenance/events`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};

export const postAdminSchedule = ({ payload }) => {
  const url = `${API_URL}/api/v1/Admin/maintenance/events`;
  return createAxiosRequest(REQUEST_TYPE.POST, url, payload);
};

export const updateAdminSchedule = ({ payload }) => {
  const url = `${API_URL}/api/v1/Admin/maintenance/events`;
  return createAxiosRequest(REQUEST_TYPE.PUT, url, payload);
};

export const deleteAdminSchedule = ({ payload: event }) => {
  const url = `${API_URL}/api/v1/Admin/maintenance/events/${event.eventId}`;
  return createAxiosRequest(REQUEST_TYPE.DELETE, url);
};

export const getProjectActiveFeatures = ({ id }) => {
  const url = `${API_URL}/api/v1/projects/${id}/activefeatures`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};
export const getAllAdminSurveys = () => {
  const url = `${API_URL}/api/v1/Admin/surveys`;
  return createAxiosRequest(REQUEST_TYPE.GET, url);
};
export const deleteAdminSurvey = ({ payload }) => {
  const url = `${API_URL}/api/v1/Admin/surveys/${payload.id}`;
  return createAxiosRequest(REQUEST_TYPE.DELETE, url);
};

export const addAdminSurvey = ({ payload }) => {
  const url = `${API_URL}/api/v1/Admin/surveys`;
  return createAxiosRequest(REQUEST_TYPE.POST, url, payload);
};

export const updateAdminSurvey = ({ payload }) => {
  const url = `${API_URL}/api/v1/Admin/surveys`;
  return createAxiosRequest(REQUEST_TYPE.PUT, url, payload);
};

export const getSurveyStatus = (requestType: string, paylaod) => {
  const URL = `${API_URL}/api/v1/users/survey`;
  return createAxiosRequest(REQUEST_TYPE[requestType], URL, paylaod);
};

export const fetchSurveyListDropdownItems = ({ id }) => {
  let url = `${API_URL}/api/v1/Admin/surveys/future-fiscal-quarter-durations`;
  if (id) {
    url = `${API_URL}/api/v1/Admin/surveys/future-fiscal-quarter-durations?surveyId=${id}`;
  }

  return createAxiosRequest(REQUEST_TYPE.GET, url);
};

export const setProjectAccessRestrictions = (id, payload) => {
  const URL = `${API_URL}/api/v1/Admin/projects/${id}`;
  return createAxiosRequest(REQUEST_TYPE.PATCH, URL, payload);
};

export const validateAdminUser = (id, payload) => {
  const URL = `${API_URL}/api/v1/Admin/users/${id}/validate`;
  return createAxiosRequest(REQUEST_TYPE.POST, URL, payload);
};

export const getProjectUsageReports = (projectId, reportId) => {
  const URL = `${API_URL}/api/v1/projects/${projectId}/reportEmbed/${reportId}`;
  return createAxiosRequest(REQUEST_TYPE.GET, URL);
};

export const getProjectEngagement = (paceId) => {
  const URL = `${API_URL}/api/v1/paces/${paceId}/engagements`;
  return createAxiosRequest(REQUEST_TYPE.GET, URL);
};

export const addProjectDocument = (payload) => {
  let url = `${API_URL}/api/v1/projects/${payload.projectId}/file`;
  return createAxiosRequestMultiPartData(REQUEST_TYPE.POST, url, payload.formData);
};

export const deleteProjectDocument = (payload) => {
  const url = `${API_URL}/api/v1/projects/${payload.projectId}/file`;
  return createAxiosRequest(REQUEST_TYPE.DELETE, url, { fileName : payload.blobName, folderPath: payload.folderPath });
};

export const fetchDbsFields = () => {
  const GET_ENDPOINT = `${API_URL}/api/v1/settings`;
  return createAxiosRequest(REQUEST_TYPE.GET, GET_ENDPOINT);
};
