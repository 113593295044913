import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  tabs,
  generalSubTabs,
  TAB_NAME,
  ITabMenu,
  PROJECT_WORKFLOW_URL,
  PROJECT_STATUS_ALL,
} from "./projectWorkflowConstants";
import AdminErrorLog from "../../components/adminWorkflows/ErrorLog/adminErrorLog";
import AdminExecutions from "../../components/adminWorkflows/Executions/adminExecutions";
import AdminSchedules from "../../components/adminWorkflows/Schedules/adminSchedules";
import AdminDefinitions from "../../components/adminWorkflows/Definitions/adminDefinitions";
import AdminCommunicationTemplates from "../../components/adminWorkflows/CommunicationTemplates/adminCommunicationTemplates";
import AdminCommunications from "../../components/adminWorkflows/Communications/adminCommunications";
import {
  clearWorkflowProjectAction,
  getWorkflowProjectAction,
} from "../../store/actions/admin-workflows-actions";
import {
  getProjectDetails,
  getProjectSupportedFeatures,
} from "../../store/actions/project-actions";
import { IAccessRight } from "../../models/user";
import { ISSPPlatformRole, ISSPUserAdminAccess } from "../../models/IRoles";
import {
  getAdminConsoleAccess,
  getAzureAppInsights,
  setPageBrTitle,
} from "../../utils/helper-utility";
import { CrashBoundary } from "../../components/CrashBoundary";
import { EProjectStatuses } from "../adminProjects/adminProjectsModel";
import ManageWorkflowsHeader from "../../components/manageWorkflowsHeader/manageWorkflowsHeader";
import { Project } from "../../components/createProject/models/project";
import { getProjectStatusAction } from "../../store/actions/project-status-actions";
import {
  isProjectAdmin,
  isPlatformPending,
  isPlatformError,
  isPorjectErrored,
} from "../../utils/helper-utility";
import { lookupModel } from "../createProject/lookupModel";
import { ProjectStatus } from "../../components/projectCard/projectCardConstants";
import { FeatureSupportedKeys, RoutePathKeys } from "../../utils/Constants";
import { checkAndLoadEMGPolicy } from "../../components/emgPolicy/constants";
import { updateProjectDetailsForEMG } from "../../store/actions/emg-policy-actions";
import { showActionToast } from "../../store/actions/notification-actions";
import t from "../../localization/en/translation.json";
import BreadcrumbComponent from "../../components/breadcrumb/Breadcrumb";

import "./projectWorkflow.scss";

type IProps = RouteComponentProps<any> & {
  adminWorkflowProjectData: {};
  getWorkflowProjectAction: Function;
  clearWorkflowProjectAction: Function;
  accessRight: IAccessRight;
  sspRoles: ISSPPlatformRole[];
  getProjectDetails: Function;
  record?: Project;
  getProjectStatus: Function;
  projectStatuses: lookupModel[];
  isFeatureSupported: boolean | null;
  getProjectSupportedFeatures: Function;
  updateProjectDetailsForEMGAction: Function;
  showActionToast: Function;
};

interface IState {
  activeSubTabIndex: number;
  tabsMenu: ITabMenu[];
  subTabs: any[];
  activeTabKey: string;
  isAdminProjectPage: boolean;
  userAdminAccess: ISSPUserAdminAccess;
}

class ProjectWorkflow extends React.Component<IProps, IState> {
  adminProjectURL = PROJECT_WORKFLOW_URL;

  constructor(props) {
    super(props);

    this.state = {
      activeSubTabIndex: -1,
      tabsMenu: [],
      subTabs: [],
      activeTabKey: "",
      isAdminProjectPage: false,
      userAdminAccess: { portalAdmin: false, portalAdminConsole: false },
    };
  }

  componentDidMount() {
    const {
      location,
      match: {
        params: { id },
      },
      getProjectDetails,
      projectStatuses,
      getProjectStatus,
      getProjectSupportedFeatures,
      record,
    } = this.props;
    if (id) {
      this.props.getWorkflowProjectAction(id);
      getProjectDetails({ id });
      getProjectSupportedFeatures({
        id,
        key: FeatureSupportedKeys.Workflow,
      });
      record && record.id && this.updatePageBrTitle();
    }
    !projectStatuses.length && getProjectStatus();

    this.checkProjectAccessible();
    const pageURL = location.pathname;
    let isAdminProjectPage = false;
    const currentTab = tabs.find((tab) => pageURL.includes(tab.key)) || tabs[0];
    if (this.adminProjectURL && pageURL.includes(this.adminProjectURL)) {
      isAdminProjectPage = true;
    }
    if (currentTab && currentTab.key) {
      let tabsMenu = tabs;
      if (isAdminProjectPage) {
        tabsMenu = tabsMenu.filter((tab) => {
          return [TAB_NAME.EMAIL_NOTIFICATIONS, TAB_NAME.WORKFLOWS].includes(
            tab.key
          );
        });
      }
      const subTabs = generalSubTabs.filter((tab) => {
        return tab.url.includes(currentTab.key);
      });
      const activeSubTabIndex = subTabs.findIndex((t) =>
        pageURL.includes(t.url)
      );
      this.setState({
        activeSubTabIndex,
        subTabs,
        tabsMenu,
        activeTabKey: currentTab.key,
        isAdminProjectPage,
      });
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const userAdminAccess = this.getUserAdminConsoleAccess();
    this.setState({ userAdminAccess });
    const appInsights = getAzureAppInsights();
    if (record && record.id && appInsights) {
      appInsights.updateProjectDetails({
        CapitalEdgeProjectFriendlyId: record.projectFriendlyId || "",
      });
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { history, adminWorkflowProjectData, record } = this.props;
    const isProjectAllowed = this.checkProjectAccessible();
    if (
      adminWorkflowProjectData &&
      adminWorkflowProjectData["projectStatus"] &&
      adminWorkflowProjectData["projectStatus"]["projectStatusName"] &&
      adminWorkflowProjectData["projectStatus"][
        "projectStatusName"
      ].toLowerCase() === EProjectStatuses.CLSD
    ) {
      this.props.showActionToast({
        message: t.permission_denied_error,
        type: "error",
        status: 403,
        redirectURL: "/",
      });
      history.push("/");
    }
    const appInsights = getAzureAppInsights();
    if (prevProps.record !== record && record && record.id) {
      this.updatePageBrTitle();
      if (appInsights) {
        appInsights.updateProjectDetails({
          CapitalEdgeProjectFriendlyId: record.projectFriendlyId || "",
        });
      }
      if (isProjectAllowed) {
        this.checkForEMGPolicy();
      }
    }
  }

  componentWillUnmount() {
    const { history } = this.props;
    this.props.clearWorkflowProjectAction();
    if (
      !history.location.pathname.includes(this.adminProjectURL) &&
      !history.location.pathname.includes(RoutePathKeys.PROJECT)
    ) {
      this.resetPageBrTitle();
    }
  }

  updatePageBrTitle() {
    const { record } = this.props;
    if (record && record.name) {
      setPageBrTitle(record.name);
    }
  }

  resetPageBrTitle() {
    setPageBrTitle();
  }

  checkForEMGPolicy = () => {
    const { accessRight, record, updateProjectDetailsForEMGAction } =
      this.props;
    checkAndLoadEMGPolicy(
      accessRight,
      updateProjectDetailsForEMGAction,
      record
    );
  };

  isProjectDraft = (projectStatusName: string) => {
    return (
      projectStatusName &&
      projectStatusName.toLowerCase() === ProjectStatus.DRAFT
    );
  };

  checkProjectAccessible = () => {
    const { projectStatuses, record, history, isFeatureSupported } = this.props;
    if (record && record.projectStatus) {
      const isPending = isPlatformPending(
        projectStatuses,
        record?.appPlatformDetails,
        record?.infrastructureStatusId
      );
      const isError = isPlatformError(
        projectStatuses,
        record?.appPlatformDetails,
        record?.infrastructureStatusId
      );
      const isProjectInError = isPorjectErrored(
        projectStatuses,
        record?.projectStatus.id
      );
      const isDraft = this.isProjectDraft(
        record?.projectStatus?.projectStatusName
      );
      const isLegacyProject = isFeatureSupported === false;
      const isProjectAdmin = this.checkProjectAdmin(record);

      if (
        !isProjectAdmin ||
        isPending ||
        isError ||
        isProjectInError ||
        isDraft ||
        record?.accessRestrictionKey === PROJECT_STATUS_ALL ||
        isLegacyProject
      ) {
        this.props.showActionToast({
          message: t.permission_denied_error,
          type: "error",
          status: 403,
          redirectURL: "/",
        });
        history.push("/");
        return false;
      } else {
        return true;
      }
    }
  };

  getUserAdminConsoleAccess = () => {
    const { accessRight, sspRoles } = this.props;
    const portalRole =
      (accessRight?.portalRoles &&
        accessRight.portalRoles.length > 0 &&
        accessRight.portalRoles[0]) ||
      "";
    return getAdminConsoleAccess(portalRole, sspRoles);
  };

  checkProjectAdmin(record) {
    const { accessRight, sspRoles } = this.props;
    return isProjectAdmin(record?.id || "", accessRight, sspRoles);
  }

  renderTabPanel = () => {
    const { activeSubTabIndex, subTabs, userAdminAccess, isAdminProjectPage } =
      this.state;
    const { record } = this.props;

    if (activeSubTabIndex < 0) {
      return;
    }
    switch (subTabs[activeSubTabIndex].key) {
      case "communications":
        return (
          <AdminCommunications
            userAdminAccess={userAdminAccess}
            isAdminProjectPage={isAdminProjectPage}
            isProjectAdmin={this.checkProjectAdmin(record)}
          />
        );
      case "templates":
        return (
          <AdminCommunicationTemplates
            userAdminAccess={userAdminAccess}
            isAdminProjectPage={isAdminProjectPage}
            isProjectAdmin={this.checkProjectAdmin(record)}
          />
        );
      case "definitions":
        return (
          <AdminDefinitions
            userAdminAccess={userAdminAccess}
            isAdminProjectPage={isAdminProjectPage}
            isProjectAdmin={this.checkProjectAdmin(record)}
          />
        );
      case "schedules":
        return (
          <AdminSchedules
            userAdminAccess={userAdminAccess}
            isAdminProjectPage={isAdminProjectPage}
            isProjectAdmin={this.checkProjectAdmin(record)}
          />
        );
      case "executions":
        return <AdminExecutions isAdminProjectPage={isAdminProjectPage} />;
      case "error-log":
        return (
          <AdminErrorLog
            userAdminAccess={userAdminAccess}
            isAdminProjectPage={isAdminProjectPage}
            isProjectAdmin={this.checkProjectAdmin(record)}
          />
        );
      default:
        return;
    }
  };

  render() {
    const { record } = this.props;
    const { isAdminProjectPage } = this.state;

    const breadcrumbObject = [
      { label: t.breadcrumb_title, href: "/", active: true },
      {
        label: `${record?.name}`,
        href: `/project/${record?.id}`,
        active: true,
      },
      {
        label: t.manage_workflows_heading,
        href: `/project/${record?.id}`,
        active: false,
      },
    ];
    return (
      <>
        <CrashBoundary>
          <div>
            {record && record?.id && (
              <div className="project-workflow-tabs">
                <div className="manage-header">
                  <BreadcrumbComponent
                    items={breadcrumbObject}
                  ></BreadcrumbComponent>
                </div>
                <div className="project-workflow-list">
                  <ManageWorkflowsHeader isAdminProjectPage={isAdminProjectPage} />
                  <div className="administration-section">
                    <section className="tab-panel-container admin-project-container">
                      {this.renderTabPanel()}
                    </section>
                  </div>
                </div>
              </div>
            )}
          </div>
        </CrashBoundary>
      </>
    );
  }
}

const mapStateToProps = ({
  workflowsReducer,
  meReducer,
  meta,
  projects: { record, isFeatureSupported },
  projectStatusReducer,
}) => {
  return {
    adminWorkflowProjectData: workflowsReducer.adminWorkflowProjectData,
    accessRight: meReducer.accessRight,
    sspRoles: meta.userRoles,
    record,
    projectStatuses: meta.projectStatuses || projectStatusReducer.projectStatus,
    isFeatureSupported,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProjectStatus: () => {
    dispatch(getProjectStatusAction());
  },
  getWorkflowProjectAction: (payload) => {
    dispatch(getWorkflowProjectAction(payload));
  },
  getProjectDetails: (payload) => {
    dispatch(getProjectDetails(payload));
  },
  clearWorkflowProjectAction: () => {
    dispatch(clearWorkflowProjectAction());
  },
  getProjectSupportedFeatures: (payload) => {
    dispatch(getProjectSupportedFeatures(payload));
  },
  updateProjectDetailsForEMGAction: (payload) => {
    dispatch(updateProjectDetailsForEMG(payload));
  },
  showActionToast: (payload) => {
    dispatch(showActionToast(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectWorkflow);
