import React, { FC } from 'react';
import TextField from '@material-ui/core/TextField';
import { InputProps as StandardInputProps } from '@material-ui/core/Input';
import { makeStyles } from "@material-ui/styles";
import themeConfig from "../config";
import './appInput.scss';

const useStyle = makeStyles({
    root: {
        width: '100%',
        fontFamily: themeConfig.fontFamilyLight,
        color: themeConfig.gray
    }
})

interface IProps {
    id?: string,
    value?: string,
    name?: string,
    isValid?: boolean,
    maxLength?: string | number,
    autoComplete?: string,
    disabled?: boolean,
    label?: string,
    placeholder?: string,
    type?: string,
    onFocus?: StandardInputProps['onFocus'],
    onChange?: StandardInputProps['onChange'],
    onBlur?: StandardInputProps['onBlur'],
    className?: string,
    inputRef?: React.RefObject<HTMLInputElement>,
}

const AppInput: FC<IProps> = ({ isValid, maxLength, type, inputRef, autoComplete, placeholder, className, ...restProps }) => {
    const classes = useStyle();

    return (
        <TextField
            className={`app-input ${className || ''} ${(isValid !== undefined && !isValid) ? 'in-valid' : ''}`}
            classes={{ ...classes }}
            autoComplete={autoComplete || "off"}
            variant="filled"
            inputProps={{ maxLength, placeholder, type }}
            ref={inputRef}
            {...restProps}
        />
    )
}

export default AppInput;
