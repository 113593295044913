import React, { FC, useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import ReactPlayer from "react-player";
import MoreMenu from "../moreMenu/moreMenu";
import moment from "moment";
import t from "../../localization/en/translation.json";
import { Project } from "../createProject/models/project";
import { updateProjectDetailsForEMG } from "../../store/actions/emg-policy-actions";
import { getMyAccessRight } from "../../store/actions/me-actions";
import {
  ProjectStatus,
  MenuActions,
  VISIBLE_PROJECT_TITLE_WIDTH,
} from "./projectCardConstants";
import { IProjectIcon } from "../../models/IProjectIcon";
import { IAccessRight } from "../../models/user";
import { getProjectDefaultRolesRequest } from "../../store/actions/default-project-actions";
import {
  addProjectDocumentAction,
  deleteProjectDocumentAction,
  flushProjectUploadedFileAction
} from "../../store/actions/project-actions";
import { AppButton, AppModal, AppTooltip, AppIcon } from "../../theme";
import { confidential, lock } from "../../theme/icons";
import "./projectCard.scss";
import CloseProjectPopup from "../MotifCloseProjectPopup/CloseProjectPopup";
import { EProjectCategory } from "../../containers/createProject/formStateModel";
import { SessionKeys } from "../../utils/Constants";
import { ISSPPlatformRole } from "../../models/IRoles";
import { isPortalAdmin } from "../../utils/helper-utility";

type CardProps = RouteComponentProps<any> & {
  onDeleteDraft: Function;
  record: Project;
  onCloseProject: Function;
  isProjectAdmin: boolean;
  showCloseBtn: boolean;
  regionName: string;
  categoryName: string;
  sectorName: string;
  icon?: IProjectIcon;
  updateProjectDetailsForEMG: Function;
  accessRight: IAccessRight;
  getProjectDefaultRolesRequest: Function;
  redirectToProjectDetails: boolean;
  getMyAccessRight: Function;
  addProjectDocument: Function;
  deleteProjectDocument: Function;
  uploadedFile: string;
  flushProjectUploadedFile: Function;
  sspRoles: ISSPPlatformRole[];
};

const ProjectCard: FC<CardProps> = ({
  updateProjectDetailsForEMG,
  onDeleteDraft,
  record,
  history,
  onCloseProject,
  isProjectAdmin,
  showCloseBtn,
  regionName,
  categoryName,
  sectorName,
  icon,
  accessRight,
  getProjectDefaultRolesRequest,
  redirectToProjectDetails,
  getMyAccessRight,
  addProjectDocument,
  deleteProjectDocument,
  uploadedFile,
  flushProjectUploadedFile,
  sspRoles
}) => {
  const [isModalOpen, toggleModal] = useState(false);
  const [isDemoModalOpen, toggleDemoModal] = useState(false);
  const [isCloseModalOpen, toggleCloseModal] = useState(false);
  const [isTooltip, withToolTip] = useState(false);
  const [isRedirectedToProject, updateIsRedirectedToProject] = useState(false);
  const draftOptions = [
    {
      id: "",
      actionName: MenuActions.DELETE,
      title: t.delete,
      isDisabled: !isProjectAdmin || !!record?.isRestricted,
    },
  ];
  const options = [
    {
      id: "",
      actionName: MenuActions.MARK,
      title: t.home_project_card_menu_mark,
      isDisabled: !isProjectAdmin || !!record?.isRestricted,
    },
  ];

  useEffect(() => {
    if (redirectToProjectDetails && !isRedirectedToProject) {
      updateIsRedirectedToProject(true);
      getMyAccessRight();
    }
    // eslint-disable-next-line
  }, [redirectToProjectDetails, isRedirectedToProject]);

  useEffect(() => {
    const callRedirectToDetails = () => {
      gotoProjectDetails();
    };
    if (isRedirectedToProject) {
      callRedirectToDetails();
    }
    // eslint-disable-next-line
  }, [isRedirectedToProject, record]);

  const handleConfirmDelete = () => {
    toggleModal(false);
    onDeleteDraft(record.id, record.name);
  };

  const checkForDemoProject = () => {
    if (!record?.isRestricted) {
      const projectAccess = accessRight.projects?.find(
        (project) => project.projectId === record.id
      );
      if (record.isDemoProject && (!projectAccess || projectAccess.deleted)) {
        getProjectDefaultRolesRequest({
          projectId: record.id,
        });
        updateIsRedirectedToProject(false);
      } else {
        gotoProjectDetails();
      }
    }
  };

  const gotoProjectDetails = () => {
    sessionStorage.setItem(
      SessionKeys.HOME_PAGE_PROJECT_TILE_CLICK,
      record.projectFriendlyId + ""
    );
    if (
      record.projectStatus.projectStatusName.toLowerCase() !==
      ProjectStatus.DRAFT
    ) {
      updateProjectDetailsForEMG({
        projectId: record.id,
        redirectUrl: `/project/${record.id}`,
        redirectInternally: true,
      });
    } else {
      history.push(`/project/${record.id}`);
    }
  };

  const checkEllipsis = ({ target }) => {
    const ctx = document.createElement("canvas").getContext("2d");

    if (!ctx) {
      return;
    }
    const text = ctx.measureText(target.innerText);

    withToolTip(text.width > VISIBLE_PROJECT_TITLE_WIDTH);
  };

  const openDemoDialog = (event) => {
    event.stopPropagation();
    toggleDemoModal(true);
  };

  const handleActiveProjectMenu = (actionName) => {
    if (actionName === MenuActions.MARK) {
      toggleCloseModal(true);
    }
  };

  const onClosePopup = useCallback(() => {
    toggleCloseModal(false);
  }, [toggleCloseModal]);

  const onProjectClose = useCallback(
    (feedback) => onCloseProject(record.id, record.name, feedback),
    [onCloseProject, record.id, record.name]
  );

  const isPortalAdminUser = () => {
    const portalRole = (accessRight?.portalRoles && accessRight.portalRoles.length > 0 && accessRight.portalRoles[0]) || "";

    return isPortalAdmin(portalRole, sspRoles);
  };

  return (
    <div>
      <div
        onClick={checkForDemoProject}
        className={`project-card ${record.isRestricted ? "remove-cursor" : ""
          } ${(record?.projectStatus?.projectStatusName.toLowerCase() ===
            ProjectStatus.DRAFT &&
            "draft") ||
          ""
          }`}
      >
        <div className="project-title">
          <div className="project-n-sector">
            <div className="project-header-container">
              <div className="project-name-container">
                <AppTooltip hide={!isTooltip} title={record.name}>
                  <div className="ellipses-1-lines" onMouseEnter={checkEllipsis}>
                    {record.name}
                  </div>
                </AppTooltip>
              </div>

              <div className="footer-btns">
                <div className="top-card-menu">
                  {record?.projectStatus?.projectStatusName.toLowerCase() ===
                    ProjectStatus.DRAFT && (
                      <MoreMenu
                        controlId="more-menu-apps"
                        optionClickFn={() => toggleModal(true)}
                        options={draftOptions}
                        stopEventBubbling
                        vertical={true}
                      />
                    )}
                  {showCloseBtn &&
                    record?.projectStatus?.projectStatusName.toLowerCase() !==
                    ProjectStatus.DRAFT && (
                      <MoreMenu
                        controlId="more-menu-apps"
                        optionClickFn={handleActiveProjectMenu}
                        options={options}
                        stopEventBubbling
                        vertical={true}
                      />
                    )}
                </div>
              </div>
            </div>
            <div className="project-card-sectors">
              {sectorName}
            </div>
            <div className="project-content-buttons">
              <span className="project-card-category-name" title={categoryName}>{categoryName}</span>
              {record?.projectStatus?.projectStatusName.toLowerCase() !==
                ProjectStatus.DRAFT &&
                record?.demoURL &&
                record?.demoURL.length !== 0 && (
                  <span>
                    <AppButton
                      color="secondary"
                      className="demo-btn"
                      onClick={(event) => openDemoDialog(event)}
                    >
                      {t.home_project_card_demo}
                    </AppButton>
                  </span>
                )}
              {record.isRestricted && (
                <AppTooltip
                  placement="top"
                  title={t.project_restriction_access_tooltip_text}
                >
                  <span className="project-card-restriction">
                    <AppIcon icon={lock} className="project-card-restriction-icon" />
                    {t.restricted}
                  </span>
                </AppTooltip>
              )}
            </div>
          </div>
        </div>
        <div className="project-card-footer">
          <span className="project-created-date">{t.project_details_meta_created + " "}
            {
              moment
                .utc(record.createdOn)
                .format("MMMM DD, yyyy")
            }
          </span>
          <span className="project-card-category">
            {record?.projectStatus?.projectStatusName.toLowerCase() ===
              ProjectStatus.DRAFT && (
                <span className="draft-project-label">{ProjectStatus.DRAFT}</span>
              )}
            {record.isConfidential && (
              <AppTooltip placement="top" title={t.shield_tooltip_text}>
                <AppIcon icon={confidential} className="project-card-confidential-icon" />
              </AppTooltip>
            )}
          </span>
        </div>
      </div>
      <div className="appModal">
        <AppModal
          showModal={isModalOpen}
          onModalClose={() => toggleModal(false)}
          onConfirm={() => handleConfirmDelete()}
          cancelBtnText={t.cancel}
          confirmBtnText={t.delete_draft_modal_delete_btn}
          title={t.delete_draft_modal_title}
          size="sm"
        >
          <div className="modal-content">
            <div className="app-title">{t.delete_draft_modal_heading}</div>
            <div className="sub-text">{t.delete_draft_modal_content}</div>
          </div>
        </AppModal>
        <div
          className="demo-video-dialog"
          onClick={() => toggleDemoModal(false)}
        >
          <AppModal
            showModal={isDemoModalOpen}
            onModalClose={() => toggleDemoModal(false)}
          >
            <div className="modal-content">
              <div>
                <ReactPlayer url={record.demoURL} playing={true} />
              </div>
            </div>
          </AppModal>
        </div>
        {record.id && (
          <CloseProjectPopup
            show={isCloseModalOpen}
            onClosePopup={onClosePopup}
            isPricingFeedback={
              record.projectCategoryId === EProjectCategory.CLI_ENG
            }
            priced={record.provisioningQuestionsDetails?.isPricingIncluded}
            onProjectClose={onProjectClose}
            projectId={record.id}
            paceId={record.paceId}
            uploadedFile={uploadedFile}
            addProjectDocument={addProjectDocument}
            deleteProjectDocument={deleteProjectDocument}
            flushProjectUploadedFile={flushProjectUploadedFile}
            isPortalAdmin={isPortalAdminUser()}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  projects,
  meta,
}) => {
  return {
    uploadedFile: projects.uploadedFile,
    sspRoles: meta.userRoles,
  };
};

const mapDisptachToProps = ({
  updateProjectDetailsForEMG,
  getProjectDefaultRolesRequest,
  getMyAccessRight,
  addProjectDocument: addProjectDocumentAction,
  deleteProjectDocument: deleteProjectDocumentAction,
  flushProjectUploadedFile: flushProjectUploadedFileAction
});

export default connect(mapStateToProps, mapDisptachToProps)(withRouter(ProjectCard));
