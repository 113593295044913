import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles, Popover } from "@material-ui/core";
import { connect, useSelector } from "react-redux";

import {
  AppModal,
  AppAlert,
  AppButton,
  AppIcon,
  MotifAppSelect,
  MotifAppTextArea,
  MotifMultiAppSelect,
  AppTooltip,
} from "../../theme";
import { exclamation, info, xMark } from "../../theme/icons";
import cloudUpload from "../../assets/images/icons/cloud-upload.svg";
import checkCircle from "../../assets/images/icons/check_circle.svg";
import t from "../../localization/en/translation.json";
import {
  fetchPricingQuestions,
  getProjectEngagement,
  fetchDbsFields,
  getProjectRecord,
  getProjectRecordOverridden
} from "../../store/apis/projectApi";
import { DropZoneErrorStatuses, ProjectDbsFolderPath } from "../../utils/Constants";
import CloseProjectConfirmationPopup from "../closeProjectConfirmationPopup/CloseProjectConfirmationPopup";
import themeConfig from "../../theme/config";

import "./CloseProjectPopup.scss";
import Loader from "../loader/loader";
import { getProjectTypesLookUpAction } from "../../store/actions/project-types-actions";
import { lookupModel } from "../../containers/createNewProject/lookupModel";
import { Project } from "../createProject/models/project";
import { APP_STATE } from "../../store/reducers";
import { AppPlatformClass } from "../appCard/models/appPlatformClass";
import { EngagementIdServiceTicketEndUrl } from "../../utils/Constants";
import { ProjectModel } from "../../containers/adminProjects/adminProjectsModel";

export type FeedbackType = {
  isTechnologyFeeIncluded?: boolean | undefined;
  isTechnologyLineItemInInvoice?: boolean;
  technologyChargedInterval?: string;
  technologyFeeNotIncludedReason?: string;
  additionalDetails?: string;
  engagementIds?: string[];
  engagementFiles?: { name: string, blobName: string }[];
  cicClause?: string;
  hasRestrictiveClauses?: string;
  hasThirdPartyRestrictiveClauses?: string;
  purchaseSuccess?: string;
};

export const CloseProjectPricingFeedback = ({
  priced,
  projectId,
  paceId,
  uploadedFile,
  onFeedback,
  addProjectDocument,
  deleteProjectDocument,
  flushProjectUploadedFile,
  isPortalAdmin,
  projectTypes,
  getProjectTypes,
  chcekDBSIntegrated,
  includeSoftClosedProject,
  selectedProject
}: {
  priced: boolean | undefined;
  projectId: string;
  paceId: string | null | undefined;
  uploadedFile: string;
  onFeedback: (feedback: FeedbackType) => void;
  addProjectDocument(payload: any): any;
  deleteProjectDocument(payload: any): any;
  flushProjectUploadedFile(): any;
  isPortalAdmin: boolean;
  projectTypes: lookupModel[];
  getProjectTypes(payload: any): void;
  chcekDBSIntegrated: (value: boolean) => void;
  includeSoftClosedProject: boolean;
  selectedProject: ProjectModel | null;
}) => {
  const [isPricingAvailable, setPricingAvailable] = useState<
    boolean | undefined
  >(() => priced);
  const [isPricingSeparate, setPricingSeparate] = useState<boolean | undefined>(
    () => undefined
  );
  const [isPricingMonthly, setPricingMonthly] = useState<string | undefined>(
    () => undefined
  );
  const [reason, setReason] = useState<string | undefined>(() => undefined);
  const [modes, setModes] = useState<{ label: string; value: string }[]>([]);
  const [additional, setAdditional] = useState("");
  const [reasons, setReasons] = useState<{ label: string; value: string }[]>([]);
  const [engagements, setEngagements] = useState<{ label: string; value: string }[]>([]);
  const [isEngagementsEmpty, setIsEngagementsEmpty] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [engagementIds, setEngagementIds] = useState<string[]>([]);
  const [documentFiles, setDocumentFiles] = useState<{ name: string; blobName: string }[]>([]);
  const [addedFileName, setAddedFileName] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hoveredFile, setHoveredFile] = useState(null);
  const [cicValue, setCicValue] = useState<string>("");
  const [successfulPurchaser, setSuccessfulPurchaser] = useState<string>("");
  const [restrictiveClause, setRestrictiveClause] = useState<string>("");
  const [thirdPartyRestrictiveClause, setThirdPartyRestrictiveClause] = useState<string>("");
  const [dbsFields, setDbsFields] = useState([]);
  const [showSuccessfulPurchaser, setShowSuccessfulPurchaser] = useState(false);
  const [projectDetails, setProjectDetails] = useState<Project>(null);
  const [showDBSFields, setShowDBSFields] = useState<boolean>(false);
  const appPlatforms = useSelector<APP_STATE, AppPlatformClass[]>(({ meta }) => meta.appPlatforms);
  const engagementTicketUrl = process.env.REACT_APP_HELP_CAPITAL_EDGE_URL?.split("=")[0] + EngagementIdServiceTicketEndUrl;

  const useStyles = makeStyles((theme) => ({
    popover: {
      padding: theme.spacing(1.4),
      fontSize: themeConfig.fontSize14,
      background: themeConfig.black,
      color: themeConfig.white
    },
    serviceLink: {
      color: themeConfig.link_blue,
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  }));

  const classes = useStyles();
  const openInfoPopOver = Boolean(anchorEl);
  const handleInfoButtonClick = (event: React.MouseEvent<HTMLElement>) => { setAnchorEl(event.currentTarget); };
  const handleClose = () => { setAnchorEl(null); };

  const isDBSIntegrated = useCallback(() => {
    let isDBSFieldsAvailable = false;
    const dbsAppPlatforms = process.env.REACT_APP_APPPLATFORMS_DBSTRANSMIT || [];
    const projectPlatformIds = projectDetails.appPlatformDetails?.map(platform => platform.appPlatformClassId || []);
    projectPlatformIds?.forEach(platformId => {
      const projectPlatform = appPlatforms.find((platform) => platform.id === platformId);
      const isDBSRelatedAppPlatform = projectPlatform ? dbsAppPlatforms.includes(projectPlatform?.key) : false;
      if (isDBSRelatedAppPlatform)
        isDBSFieldsAvailable = true
    });
    chcekDBSIntegrated(isDBSFieldsAvailable);
    return isDBSFieldsAvailable
  }, [appPlatforms, projectDetails, chcekDBSIntegrated]);

  useEffect(() => {
    (async () => {
      try {
        setShowLoader(true);
        if (selectedProject) {
          setProjectDetails(selectedProject);
        } else {
          const projectRecord = includeSoftClosedProject ? await getProjectRecordOverridden({ id: projectId }) :
            await getProjectRecord({ id: projectId });
          if (projectRecord && projectRecord?.data) {
            setProjectDetails(projectRecord?.data);
          }
        }
        const dbsFieldsResponse = await fetchDbsFields();
        if (dbsFieldsResponse && dbsFieldsResponse?.data?.dbs) {
          setDbsFields(dbsFieldsResponse.data.dbs)
        }
        setShowLoader(false);
        const response = await fetchPricingQuestions();
        const data = response?.data as {
          pricingQuestionKey: string;
          name: string;
          id: string;
        }[];
        setModes(
          data
            .filter(
              (option) =>
                option.pricingQuestionKey === "TechnologyChargedInterval"
            )
            .map((option) => ({ label: option.name, value: option.id }))
        );
        setReasons(
          data
            .filter(
              (option) =>
                option.pricingQuestionKey === "TechnologyFeeNotIncludedReason"
            )
            .map((option) => ({ label: option.name, value: option.id }))
        );
        const engagementResponse = await getProjectEngagement(paceId);
        const engagementData = engagementResponse?.data as {
          id: string;
          name: string;
        }[];
        setEngagements(
          engagementData.map((option) => ({
            label: option.name,
            value: option.id,
          }))
        );
        if (!engagementData.length)
          setIsEngagementsEmpty(true);
      } catch { }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (projectTypes && !projectTypes.length)
      getProjectTypes({ loading: false });
  }, [projectTypes, getProjectTypes])

  useEffect(() => {
    const projectType = projectTypes?.find((type) => type.id === projectDetails?.projectTypeId);
    if (projectType?.key === "B") {
      setShowSuccessfulPurchaser(true);
    }
    if (projectDetails && appPlatforms)
      setShowDBSFields(isDBSIntegrated());
  }, [projectDetails, projectTypes, isDBSIntegrated, appPlatforms]);

  useEffect(() => {
    const feedbackData: FeedbackType = {
      isTechnologyFeeIncluded: isPricingAvailable,
      ...(() =>
        isPricingAvailable
          ? {
            isTechnologyLineItemInInvoice: isPricingSeparate,
            technologyChargedInterval: isPricingMonthly,
          }
          : { technologyFeeNotIncludedReason: reason })(),
      additionalDetails: additional,
      engagementIds: engagementIds,
      engagementFiles: documentFiles.map(file => {
        return {
          name: file.name,
          blobName: file.blobName
        };
      }),
      cicClause: cicValue,
      hasRestrictiveClauses: restrictiveClause,
      hasThirdPartyRestrictiveClauses: thirdPartyRestrictiveClause,
    };

    if (showSuccessfulPurchaser)
      feedbackData.purchaseSuccess = successfulPurchaser

    onFeedback(feedbackData);
    if (isPricingAvailable) {
      setReason(undefined);
    } else {
      setPricingSeparate(undefined);
      setPricingMonthly(undefined);
    }
    if (uploadedFile && uploadedFile !== "" && addedFileName !== "") {
      setDocumentFiles((current) => [
        ...current,
        { name: addedFileName, blobName: uploadedFile },
      ]);
      setAddedFileName("");
      flushProjectUploadedFile();
    }
    // eslint-disable-next-line
  }, [
    isPricingAvailable,
    isPricingSeparate,
    isPricingMonthly,
    reason,
    additional,
    engagementIds,
    onFeedback,
    uploadedFile,
    addedFileName,
    documentFiles,
    cicValue,
    restrictiveClause,
    thirdPartyRestrictiveClause,
    successfulPurchaser
  ]);

  const handleEngagementChange = ({
    target: { value },
  }: {
    target: { value: string[] };
  }) => {
    setEngagementIds(value);
  };

  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();
    acceptedFiles.forEach((acceptedFile) => {
      formData.append("file", acceptedFile);
    });
    formData.append("folderPath", ProjectDbsFolderPath);
    if (acceptedFiles.length > 0) {
      addProjectDocument({ projectId, formData });
      setAddedFileName(acceptedFiles[0].name);
    }
    // eslint-disable-next-line
  }, []);

  const onDragOver = (e) => {
    const event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  };

  const onDragEnter = (e) => {
    const event = e as Event;
    event.stopPropagation();
  };

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: [".pdf", ".doc", ".docx", ".ppt"],
    maxFiles: 1,
    maxSize: 26214400,
    onDrop,
    onDragOver,
    onDragEnter,
    noDragEventsBubbling: true,
  });

  const renderFileUploadError = () => {
    const [
      {
        errors: [{ code }],
      },
    ] = fileRejections;

    if (code === DropZoneErrorStatuses.FILE_TOO_LARGE) {
      return t.engagement_document_upload_file_size_validation_message;
    }

    if (code === DropZoneErrorStatuses.FILE_INVALID_TYPE) {
      return t.engagement_document_upload_file_type_validation_message;
    }

    return t.engagement_document_upload_validation_message;
  };

  const handleDeleteDocument = (blobName: string) => {
    deleteProjectDocument({ projectId, blobName, folderPath: ProjectDbsFolderPath });
    setDocumentFiles((current) => current.filter((file) => file.blobName !== blobName));
  };

  const getDbsFieldDetails = (key: string) => {
    return dbsFields.find((field) => field.key === key);
  }

  return (
    <>
      <Loader isLoading={showLoader} isFullSize={false} />
      <div className={"close-project-feedback"}>
        <div className={"row"}>
          <div className="question">
            <span>
              {t.is_pricing_available}
              (Click {" "}
              <a
                className={"pricing-information-link"}
                href={process.env.REACT_APP_PRICING_SITE}
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                pricing guidance
              </a> {" "}
              for details<span className="asterisk">*</span>)
            </span>
          </div>
          <div className={"feedback-input"}>
            <MotifAppSelect
              value={
                isPricingAvailable === true
                  ? "Yes"
                  : isPricingAvailable === false
                    ? "No"
                    : undefined
              }
              displayEmpty={true}
              placeholder="Select"
              onChange={({ target: { value } }) =>
                setPricingAvailable((value as string) === "Yes" ? true : false)
              }
              options={[
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
              ]}
            />
          </div>
        </div>
        {isPricingAvailable === true ? (
          <>
            <div className={"row"}>
              <span className={"question"}>{t.is_pricing_separate}</span>
              <div className={"feedback-input"}>
                <MotifAppSelect
                  value={
                    isPricingSeparate === true
                      ? "Yes"
                      : isPricingSeparate === false
                        ? "No"
                        : "Select"
                  }
                  displayEmpty={false}
                  placeholder="Select"
                  onChange={({ target: { value } }) =>
                    setPricingSeparate((value as string) === "Yes" ? true : false)
                  }
                  options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" },
                  ]}
                />
              </div>
            </div>
            <div className={"row"}>
              <span className={"question"}>{t.is_pricing_monthly}</span>
              <div className={"feedback-input"}>
                <MotifAppSelect
                  value={isPricingMonthly || "Select"}
                  displayEmpty={false}
                  placeholder="Select"
                  onChange={({ target: { value } }) =>
                    setPricingMonthly(value as string)
                  }
                  options={modes}
                />
              </div>
            </div>
          </>
        ) : (
          isPricingAvailable !== undefined && (
            <div className={"row"}>
              <span className={"question"}>{t.what_reasons_no_pricing}</span>
              <div className={"feedback-input"}>
                <MotifAppSelect
                  value={reason}
                  displayEmpty={false}
                  placeholder="Select"
                  onChange={({ target: { value } }) => setReason(value as string)}
                  options={reasons}
                />
              </div>
            </div>
          )
        )}
        <MotifAppTextArea
          placeholder={
            isPricingAvailable !== undefined
              ? isPricingAvailable
                ? t.pricing_avail_additional_information
                : t.pricing_not_avail_additional_information
              : "Enter a description..."
          }
          maxLength={500}
          label={t.additional_details}
          isRequired={true}
          onChange={({ target: { value } }) => setAdditional(value)}
          rows={3}
        />

        {showDBSFields &&
          <>
            <div className="divider"></div>
            <AppAlert severity="info" className="info-alert-custom">
              {t.data_bureau_service_message}
            </AppAlert>
            <div className={"file-upload-wrapper"}>
              <span className="input-label">
                {t.engagement_document_upload_title}
                {!isPortalAdmin && <span className="asterisk">*</span>}
              </span>
              <div
                className={
                  !!fileRejections.length
                    ? "drag-drop-wrapper-alert"
                    : "drag-drop-wrapper"
                }
                style={{ pointerEvents: documentFiles.length >= 5 ? "none" : "auto" }}
              >
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  {!!fileRejections.length ? (
                    <AppIcon icon={exclamation} />
                  ) : (
                    <img
                      src={cloudUpload}
                      className="upload-icon-size"
                      alt="Upload"
                    />
                  )}
                  <span className="upload-text">
                    {!!fileRejections.length
                      ? renderFileUploadError()
                      : t.engagement_document_upload_area_title_1}
                  </span>
                  <span className="upload-options">
                    {t.engagement_document_upload_area_title_2}
                  </span>
                </div>
              </div>
              {documentFiles !== undefined && documentFiles.length > 0 && (
                <div className={"file-list"}>
                  {documentFiles.map((file) => (
                    <div
                      key={file.name}
                      className={"file"}
                      onMouseEnter={() => setHoveredFile(file.name)}
                      onMouseLeave={() => setHoveredFile(null)}
                    >
                      <span className={"filename"} title={file.name}>{file.name}</span>
                      <AppButton
                        className={"file-icon-cross"}
                        variant="text"
                        onClick={() => handleDeleteDocument(file.blobName)}
                      >
                        {hoveredFile === file.name ? (
                          <AppIcon icon={xMark} className="Close-circleCheck" />
                        ) : (
                          <img
                            src={checkCircle}
                            className="Close-circleCheck"
                            alt=""
                          />
                        )}
                      </AppButton>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {!showLoader &&
              <>
                <div className="row">
                  <div className="question">
                    <span className="input-label">
                      {t.engagement_id}
                      {!isPortalAdmin && <span className="asterisk">*</span>}
                      {isEngagementsEmpty && !isPortalAdmin &&
                        <div className="no-engagement-info-msg">
                          <Popover
                            open={openInfoPopOver}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                            style={{ maxWidth: '60%' }}
                          >
                            <div className={classes.popover}>
                              {t.no_engagement_id_info_message_line_1}
                              <span onClick={() => window.open(`${engagementTicketUrl}`, '_blank')} className={classes.serviceLink}>
                                {t.service_request_link}
                              </span>
                              {t.no_engagement_id_info_message_line_2}
                            </div>
                          </Popover>
                          <AppIcon icon={info} onClick={handleInfoButtonClick} />
                        </div>
                      }
                    </span>
                  </div>
                  <div className="feedback-input">
                    <MotifMultiAppSelect
                      value={engagementIds || []}
                      isRequired={false}
                      multiple={true}
                      onChange={handleEngagementChange}
                      displayEmpty={true}
                      emptyValue={t.no_items_found}
                      options={engagements.map((engagement) => engagement.value) || []}
                    />
                  </div>
                </div>
                {dbsFields && !!dbsFields.length &&
                  <>
                    {showSuccessfulPurchaser && (
                      <div className="row">
                        <div className="question">
                          <span className="input-label">
                            {getDbsFieldDetails('PRCSUCCESS')?.title}
                          </span>
                          {!isPortalAdmin && <span className="asterisk">*</span>}
                          <AppTooltip title={getDbsFieldDetails('PRCSUCCESS')?.description || ''}>
                            <AppIcon icon={info} style={{ fontSize: 14 }} />
                          </AppTooltip>
                        </div>
                        <div className="feedback-input">
                          <MotifAppSelect
                            value={successfulPurchaser}
                            isRequired={false}
                            onChange={(e: any) => { setSuccessfulPurchaser(e.target.value) }}
                            displayEmpty={true}
                            emptyValue={t.no_items_found}
                            options={getDbsFieldDetails('PRCSUCCESS')?.values.map((value) => ({ value: value, label: value })) || []}
                          />
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="question">
                        <span className="input-label">
                          {getDbsFieldDetails('HASRSTRCTCLS')?.title}
                        </span>
                        {!isPortalAdmin && <span className="asterisk">*</span>}
                        <AppTooltip title={getDbsFieldDetails('HASRSTRCTCLS')?.description || ''}>
                          <AppIcon icon={info} style={{ fontSize: 14 }} />
                        </AppTooltip>
                      </div>
                      <div className={"feedback-input"}>
                        <MotifAppSelect
                          value={restrictiveClause}
                          isRequired={false}
                          onChange={(e: any) => { setRestrictiveClause(e.target?.value) }}
                          displayEmpty={true}
                          emptyValue={t.no_items_found}
                          options={getDbsFieldDetails('HASRSTRCTCLS')?.values.map((value) => ({ value: value, label: value })) || []}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="question">
                        <span className="input-label">
                          {getDbsFieldDetails('THIRDPARTYRSTRCL')?.title}
                        </span>
                        {!isPortalAdmin && <span className="asterisk">*</span>}
                        <AppTooltip title={getDbsFieldDetails('THIRDPARTYRSTRCL')?.description || ''}>
                          <AppIcon icon={info} style={{ fontSize: 14 }} />
                        </AppTooltip>
                      </div>
                      <div className={"feedback-input"}>
                        <MotifAppSelect
                          value={thirdPartyRestrictiveClause}
                          isRequired={false}
                          onChange={(e: any) => { setThirdPartyRestrictiveClause(e.target.value) }}
                          displayEmpty={true}
                          emptyValue={t.no_items_found}
                          options={getDbsFieldDetails('THIRDPARTYRSTRCL')?.values.map((value) => ({ value: value, label: value })) || []}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="question">
                        <span className="input-label">
                          {getDbsFieldDetails('CICClause')?.title}
                        </span>
                        {!isPortalAdmin && <span className="asterisk">*</span>}
                        <AppTooltip title={getDbsFieldDetails('CICClause')?.description || ''}>
                          <AppIcon icon={info} style={{ fontSize: 14 }} />
                        </AppTooltip>
                      </div>
                      <div className="feedback-input">
                        <MotifAppSelect
                          value={cicValue}
                          name="cicClause"
                          emptyValue={t.no_items_found}
                          label={``}
                          options={getDbsFieldDetails('CICClause')?.values.map((value) => ({ value: value, label: value })) || []}
                          onChange={(e: any) => setCicValue(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                }
              </>
            }
          </>
        }
      </div>
    </>
  );
};

const CloseProjectPopup = ({
  show,
  onClosePopup,
  isPricingFeedback,
  priced,
  onProjectClose,
  projectId,
  paceId,
  uploadedFile,
  addProjectDocument,
  deleteProjectDocument,
  flushProjectUploadedFile,
  isPortalAdmin,
  projectTypes,
  getProjectTypes,
  includeSoftClosedProject = false,
  projectDetails = null
}: {
  show: boolean;
  onClosePopup: () => void;
  isPricingFeedback: boolean;
  priced: boolean | undefined;
  onProjectClose: (feedback: FeedbackType) => void;
  projectId: string | undefined;
  paceId: string | null | undefined;
  uploadedFile: string;
  addProjectDocument;
  deleteProjectDocument;
  flushProjectUploadedFile;
  isPortalAdmin: boolean;
  projectTypes: lookupModel[];
  getProjectTypes(): void;
  includeSoftClosedProject?: boolean;
  projectDetails?: ProjectModel | null;
}) => {
  const [feedback, setFeedback] = useState<FeedbackType>({});
  const [isDBSIntegrated, setIsDBSIntegrated] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    onProjectClose(feedback);
    onClosePopup();
  }, [feedback, onClosePopup, onProjectClose]);
  const onFeedback = useCallback(
    (feedback: FeedbackType) => setFeedback(feedback),
    [setFeedback]
  );
  const canConfirm = isPricingFeedback
    ? feedback.isTechnologyFeeIncluded
      ? feedback.technologyChargedInterval != null &&
      feedback.isTechnologyLineItemInInvoice != null
      : !!feedback.technologyFeeNotIncludedReason
    : true;
  const areMandatoryFieldsFilled = feedback != null;

  const areDBSMandatoryFieldsFilled = !isDBSIntegrated ? true : !isPortalAdmin ? (
    feedback.engagementIds != null &&
    feedback.engagementIds.length > 0 &&
    feedback.engagementFiles != null &&
    feedback.engagementFiles.length > 0 &&
    feedback.hasRestrictiveClauses?.length &&
    feedback.hasThirdPartyRestrictiveClauses?.length &&
    feedback.cicClause?.length
  ) : feedback != null;

  const areMandatoryConditionalFieldsFilled = !isDBSIntegrated ? true : isPortalAdmin ? true : 'purchaseSuccess' in feedback ? Boolean(feedback.purchaseSuccess?.length) : true;
  const canConfirmWithMandatoryFields = isPricingFeedback
    ? canConfirm && areMandatoryFieldsFilled && areDBSMandatoryFieldsFilled && areMandatoryConditionalFieldsFilled
    : canConfirm;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };
  const handleConfirmationConfirm = () => {
    setShowConfirmation(false);
    handleClose();
  };
  const handleConfirmPopUpClose = () => {
    isPricingFeedback ? setShowConfirmation(true) : handleClose();
  };

  useEffect(() => {
    setShowConfirmation(!isPricingFeedback && show);
  }, [show, isPricingFeedback]);
  const handleConfirmationCloseModel = () => {
    setShowConfirmation(false);
    onClosePopup();
  };
  return (
    <>
      {!isPricingFeedback ? (
        <CloseProjectConfirmationPopup
          show={showConfirmation}
          onClosePopup={handleConfirmationCloseModel}
          handleClose={handleConfirmationConfirm}
        />
      ) : (
        <>
          <AppModal
            newDesign={true}
            showModal={show}
            onModalClose={() => onClosePopup()}
            onConfirm={() => handleConfirmPopUpClose()}
            cancelBtnText={t.cancel}
            confirmBtnText={isDBSIntegrated ? t.close_project_modal_client_close_button : t.close_project_modal_client_close_button_without_upload}
            disabledValue={!canConfirmWithMandatoryFields}
            title={t.close_project_modal_title}
            size="lg"
            fullWidth={false}
            modalClass={"custom-engagement-close-project"}
          >
            <div className="motif-close-project modal-content">
              <AppAlert severity="error" className="error-alert-custom">
                {t.close_project_modal_confirm_warning}
              </AppAlert>
              <CloseProjectPricingFeedback
                priced={priced}
                onFeedback={onFeedback}
                paceId={paceId}
                projectId={projectId}
                uploadedFile={uploadedFile}
                addProjectDocument={addProjectDocument}
                deleteProjectDocument={deleteProjectDocument}
                flushProjectUploadedFile={flushProjectUploadedFile}
                isPortalAdmin={isPortalAdmin}
                projectTypes={projectTypes}
                getProjectTypes={getProjectTypes}
                chcekDBSIntegrated={setIsDBSIntegrated}
                includeSoftClosedProject={includeSoftClosedProject}
                selectedProject={projectDetails}
              />
            </div>
          </AppModal>
          <CloseProjectConfirmationPopup
            show={showConfirmation}
            onClosePopup={handleConfirmationClose}
            handleClose={handleConfirmationConfirm}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = ({
  projectTypesReducer
}) => {
  return {
    projectTypes: projectTypesReducer.projectTypes,
  };
};

const mapDisptachToProps = ({
  getProjectTypes: (payload) => getProjectTypesLookUpAction(payload)
});

export default connect(mapStateToProps, mapDisptachToProps)(CloseProjectPopup);
