import * as type from "../action-types";
import { NOTIFICATION } from "./admin-portal-communication-actions";

export const getUserPanns = () => ({
  type: type.GET_PAANS_POLICY_REQUEST,
});

export const getUserPannsSuccess = (payload: any) => ({
  type: type.GET_PAANS_POLICY_SUCCESS,
  payload,
});
export const storeUrlParams=(payload)=>({
  type:type.STORE_URL_ERRORCODES,
  payload
  })
  export const clearUrlParams=()=>({
    type:type.CLEAR_URL_ERRORCODES
  })
export const getUserPannsFailue = (payload: any) => ({
  type: type.GET_PAANS_POLICY_FAILURE,
  payload,
});

export const getMyAccessRight = (payload: any) => ({
  type: type.GET_MY_ACCESS_RIGHT_REQUEST,
});

export const getMyAccessRightSuccess = (payload: any) => ({
  type: type.GET_MY_ACCESS_RIGHT_SUCCESS,
  payload,
});

export const getMyAccessRightFailure = (payload: any) => ({
  type: type.GET_MY_ACCESS_RIGHT_FAILURE,
  payload,
});
export const appErrorHandlerRequest = (payload: any) => ({
  type: type.APP_ERROR_HANDLER_REQUEST,
  payload,
});
export const appErrorHandlerSuccess = (payload: any) => ({
  type: type.APP_ERROR_HANDLER_SUCCESS,
  payload,
});
export const appErrorHandlerFailure = (payload: any) => ({
  type: type.APP_ERROR_HANDLER_FAILURE,
  payload,
});

export const flushAppErrorNotification = () => ({
  type: type.FLUSH_APP_ERROR_NOTIFICATION,
});
export const ON_ALL_ACTIVE_NOTIFICATIONS_ACTION = (
  payload: NOTIFICATION[]
) => ({
  type: type.GET_ALL_ACTIVE_NOTIFICATIONS_SUCCESS,
  payload,
});
export const ON_ALL_ACTIVE_NOTIFICATIONS_FAILED_ACTION = () => ({
  type: type.GET_ALL_ACTIVE_NOTIFICATIONS_FAILURE,
});
export const GET_ALL_ACTIVE_NOTIFICATIONS_ACTION = () => ({
  type: type.GET_ALL_ACTIVE_NOTIFICATIONS,
});

export const READ_NOTIFICATION_ACTION = (payload: {
  notificationId: string;
}) => ({
  type: type.READ_NOTIFICATION,
  payload,
});

export const ON_READ_NOTIFICATION_ACTION = (payload: {
  notificationId: string;
}) => ({ type: type.READ_NOTIFICATION_SUCCESS, payload });

export const READ_NOTIFICATION_FAILURE_ACTION = (payload: {
  notificationId: string;
}) => ({ type: type.READ_NOTIFICATION_FAILURE, payload });

export const CLOSE_NOTIFICATION_ACTION = (payload: {
  notificationId: string;
}) => ({
  type: type.CLOSE_NOTIFICATION,
  payload,
});

export const ON_CLOSE_NOTIFICATION_ACTION = (payload: {
  notificationId: string;
}) => ({
  type: type.CLOSE_NOTIFICATION_SUCCESS,
  payload,
});

export const CLOSE_NOTIFICATION_FAILURE_ACTION = (payload: {
  notificationId: string;
}) => ({
  type: type.CLOSE_NOTIFICATION_FAILURE,
  payload,
});
export const getSurveyDetails = () => ({
  type: type.GET_SURVEY_DETAILS,
});
export const getSurveyStatusSuccess = (payload: any) => ({
  type: type.GET_SURVEY_DETAILS_SUCCESS,
  payload,
});
export const getSurveyStatusFailure = (payload: any) => ({
  type: type.GET_SURVEY_DETAILS_FAILURE,
  payload,
});
export const delaySurvey = (payload) => ({
  type: type.DELAY_SURVEY,
  payload,
});
export const showChangeMailPopUp=(payload)=>({
type:type.SHOW_CHANGE_EMAIL_POPUP,
payload,
});
export const showMultipleMailPopUp=(payload)=>({
  type:type.SHOW_MULTIPLE_EMAIL_POPUP,
  payload,
  });
export const getUserProfilePhoto = () => ({
  type: type.GET_USER_PHOTO,
});
