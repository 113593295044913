import { put, takeLatest, call } from 'redux-saga/effects';
import * as type from '../action-types';
import { fetchProjectTypes, updateProjectType, addProjectType } from '../apis/projectApi';

function* getProjectTypes(action) {
  try {
    const data = yield call(fetchProjectTypes);
    yield put({
      type: type.GET_PROJECT_TYPES_SUCCESS,
      payload: data
    });
  } catch ({ message }) {
    yield put({
      type: type.GET_PROJECT_TYPES_FAILURE,
      payload: message
    });
  }
}

function* putProjectType(action) {
  try {
    const data = yield call(updateProjectType, action);
    yield put({
      type: type.UPDATE_PROJECT_TYPE_SUCCESS,
      payload: data
    });
  } catch ({ message }) {
    yield put({
      type: type.UPDATE_PROJECT_TYPE_FAILURE,
      payload: message
    });
  }
}

function* addProjectTypeSaga(action) {
  try {
    const data = yield call(addProjectType, action);
    yield put({
      type: type.ADD_PROJECT_TYPE_SUCCESS,
      payload: data
    });
  } catch ({ message }) {
    yield put({
      type: type.ADD_PROJECT_TYPE_FAILURE,
      payload: message
    });
  }
}

export function* getProjectTypesActionWatcher() {
  yield takeLatest(type.GET_PROJECT_TYPES, getProjectTypes);
  yield takeLatest(type.GET_PROJECT_TYPES_REQUEST, getProjectTypes);
  yield takeLatest(type.UPDATE_PROJECT_TYPE_REQUEST, putProjectType);
  yield takeLatest(type.ADD_PROJECT_TYPE_REQUEST, addProjectTypeSaga);
}
